// Interfaces
import type { NuxtAxiosInstance } from '@nuxtjs/axios';

// Abstract parent class
import type { DocumentNode } from 'graphql';

// Queries
import { getDecodedNumericId } from '@@/src/shared/lib/utils/commonUtils';
import { serializeFacets, specsArrayToObject } from '@@/src/shared/api/Lot/helpers/serializeUtills';
import { convertGraphQLVariables } from '@@/src/shared/api/Lot/helpers/convertGraphQLVariables';
import { BaseClient } from '@@/src/shared/api/Base/Base.client';
import type {
  Node,
  SerializedSpecs,
  Specs,
} from '@@/src/shared/api/Lot/types';
import layoutsListQuery from './queries/layoutsList.query.graphql';
import lotSpecsQuery from './queries/lotSpecs.query.graphql';
import lotFacetsQuery from './queries/lotFacets.query.graphql';
import lotsListQuery from './queries/lotsList.query.graphql';
import pantrySpacesQuery from './queries/pantrySpaces.query.graphql';
import pantrySpacesCountQuery from './queries/pantrySpacesCount.query.graphql';
import parkingPantrySpacesQuery from './queries/parkingPantrySpaces.query.graphql';
import parkingPantrySpacesCountQuery from './queries/parkingPantrySpacesCount.query.graphql';
import parkingPantrySpacesSpecsQuery from './queries/parkingPantrySpacesSpecs.query.graphql';
import parkingSpacesQuery from './queries/parkingSpaces.query.graphql';
import parkingSpacesCountQuery from './queries/parkingSpacesCount.query.graphql';

// Helpers & utils

// Types

/**
 * Включить раздельный функционал для паркингов (STRANA-877 / STRANA-908)
 */
const ENABLE_PARKING_PANTRY_SPECIAL_LOGIC = true;

class LotClient extends BaseClient {
  readonly #enableParkingPantrySpecialLogic: boolean;
  constructor($axios: NuxtAxiosInstance) {
    super($axios);
    this.#enableParkingPantrySpecialLogic = ENABLE_PARKING_PANTRY_SPECIAL_LOGIC;
  }

  async getList(params: Record<string, any>, flags = { isParkingPantry: false }) {
    const isParkingPantry = this.#enableParkingPantrySpecialLogic && flags?.isParkingPantry;

    if (isParkingPantry) {
      return this.#fetchParkingPantryList(params);
    }

    return this.#fetchLayoutsList(params);
  }

  async getLotsList(params: Record<string, any>) {
    return this.#fetchLotsList(params);
  }

  /**
   * Получение фасетов
   */
  async getFacets(params: Record<string, any>, flags = { isParkingPantry: false }) {
    try {
      const isParkingPantry = this.#enableParkingPantrySpecialLogic && flags?.isParkingPantry;

      const variables: Record<string, any> = convertGraphQLVariables(params.project, params);

      if (variables.project && !Array.isArray(variables.project)) {
        variables.project = [variables.project];
      }

      // allGlobalFlatsFacets и allGlobalParkingSpacesFacets принимают проекты и корпуса по-разному.
      variables.projects = variables.project;
      variables.buildings = variables.building;

      // noinspection ES6MissingAwait
      const requestsQueue = [
        this.sendRequest(lotFacetsQuery, variables),
      ];

      if (isParkingPantry) {
        requestsQueue.push(
          this.#fetchParkingPantryCount(params.parking_type, variables),
        );
      }

      const [
        {
          flats: { facets, count: flatsCount },
          parking: { facets: parkingFacets },
        },
        parkingPantryCount,
      ] = await Promise.all(requestsQueue);

      const serializedFacets = serializeFacets(facets);
      const serializedParkingFacets = serializeFacets(parkingFacets);
      return {
        facets: {
          ...serializedFacets,
          building: [...serializedFacets.building, ...serializedParkingFacets.building],
        },
        count: parkingPantryCount ?? flatsCount,
      };
    }
    catch (error: any) {
      this.getError('getFacets', error);

      return {};
    }
  }

  /**
   * Получение спеков
   */
  async getSpecs(params: Record<string, any>) {
    try {
      const variables: Record<string, any> = convertGraphQLVariables(params.project, params);
      if (variables.project && !Array.isArray(variables.project)) {
        variables.project = [variables.project];
      }
      const flatsSpecsRequest: Promise<Specs> = this.sendRequest(lotSpecsQuery, variables);
      let parkingPantrySpecsRequest: Promise<null | Specs> = Promise.resolve(null);

      if (this.#enableParkingPantrySpecialLogic) {
        parkingPantrySpecsRequest = this.sendRequest(parkingPantrySpacesSpecsQuery, variables);
      }

      const [
        flatsSpecs,
        parkingPantrySpecs,
      ] = await Promise.all([flatsSpecsRequest, parkingPantrySpecsRequest]);

      if (!parkingPantrySpecs) {
        return flatsSpecs;
      }

      const serializedFlatsSpecs: SerializedSpecs = specsArrayToObject(flatsSpecs?.result || []);
      const serializedParkingPantrySpecs: SerializedSpecs = specsArrayToObject(parkingPantrySpecs?.result ?? []);

      const {
        building: parking_building,
        full_final_price: parking_price,
        area: parking_area,
      } = serializedParkingPantrySpecs;

      // TODO: получать от API
      const parking_type = [
        { label: 'Любой тип', value: '' },
        { label: 'Паркинг', value: 'PARKING' },
        { label: 'Кладовки', value: 'PANTRY' },
      ];

      return {
        ...serializedFlatsSpecs,
        building: [...serializedFlatsSpecs.building, ...parking_building]
          .filter(({ label, value }) => label && value),
        parking_price,
        parking_area,
        parking_type,
      };
    }
    catch (error: any) {
      this.getError('getSpecs', error);

      return {};
    }
  }

  async #fetchParkingPantryCount(type: string, variables: Record<string, any>): Promise<number> {
    try {
      let query;
      switch (type) {
        case 'PARKING':
          query = parkingSpacesCountQuery;
          break;
        case 'PANTRY':
          query = pantrySpacesCountQuery;
          break;
        default:
          query = parkingPantrySpacesCountQuery;
          break;
      }

      const response = await this.sendRequest(query, variables);

      return Number(response?.data?.result?.count || 0);
    }
    catch (error: any) {
      this.getError('fetchParkingPantryCount', error);

      return 0;
    }
  }

  async #fetchLayoutsList(params: Record<string, any>) {
    const variables = convertGraphQLVariables(params.project, params);

    return this.#fetchList(
      layoutsListQuery,
      variables,
      ({ node }: { node: Node }) => {
        node.flatCount = node.lotsQuantity = Number(node.flatCount) || 0;
        node.isLayout = node.flatCount > 1;
        node.type = node.type?.toLocaleLowerCase();
        node.graphqlId = node.id || node.flatId;
        node.flatId = node.flatId ? String(getDecodedNumericId(node.flatId)) : null;
        node.firstFlatId = node.isLayout ? node.flatId : null;
        node.id = node.isLayout
          ? (node.id && String(getDecodedNumericId(node.id) || ''))
          : node.flatId;
        node.layoutId = (node.layout?.id && String(getDecodedNumericId(node.layout?.id))) || null;

        return node;
      },
    );
  }

  async #fetchLotsList(params: Record<string, any>) {
    const variables = convertGraphQLVariables(params.project, params);

    return this.#fetchList(
      lotsListQuery,
      variables,
      ({ node }: { node: Node }) => {
        node.isLayout = false;
        node.type = node.type?.toLocaleLowerCase();
        node.graphqlId = node.id || node.flatId;
        node.flatId = node.flatId ? String(getDecodedNumericId(node.flatId)) : null;
        node.firstFlatId = node.flatId;
        node.id = node.flatId;

        return node;
      },
    );
  }

  async #fetchParkingPantryList(params: Record<string, any>) {
    let query;
    switch (params.parking_type || '') {
      case 'PARKING':
        query = parkingSpacesQuery;
        break;
      case 'PANTRY':
        query = pantrySpacesQuery;
        break;
      default:
        query = parkingPantrySpacesQuery;
        break;
    }

    const variables = convertGraphQLVariables(params.project, params);

    const additionalMapData = {};

    return this.#fetchList(query, variables, ({ node }: { node: Record<string, any> }) => {
      let type = node.type.toLocaleLowerCase();
      if (!['parking', 'pantry'].includes(type)) {
        type = 'pantry';
      }

      return { ...node, type, ...additionalMapData };
    });
  }

  async #fetchList(query: DocumentNode, variables: Record<string, any>, transformNode: any = null) {
    try {
      const response = await this.sendRequest(query, variables);

      const { hasNextPage = false, endCursor = null } = (response?.result?.pageInfo || {});

      return {
        count: Number(response?.result?.count || 0),
        results: response?.result?.edges.map(transformNode),
        next: hasNextPage && endCursor ? endCursor : null,
      };
    }
    catch (error: any) {
      this.getError('#fetchList', error);

      return {};
    }
  }
}

export { LotClient };
