var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.container
  }, [_c('h3', {
    staticClass: "h2",
    class: _vm.$style.title
  }, [_vm._v("\n    Ошибка 404.\n  ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.text
  }, [_vm._v("\n    Страница не найдена\n  ")]), _vm._v(" "), _c('s-button', {
    staticClass: "btn",
    class: _vm.$style.btn,
    attrs: {
      "href": "/",
      "size": "x-large",
      "n-link": ""
    }
  }, [_vm._v("\n    Вернуться на главную\n  ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };