var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', {
    class: _vm.$style.main,
    attrs: {
      "id": "app"
    }
  }, [_c('nuxt')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };