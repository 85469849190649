export const state = () => (
  {
    manager: undefined,
  }
);

export const mutations = {
  SET_MANAGER(
    state,
    filter,
  ) {
    state.manager = filter;
  },
};

export const actions = {
  setManager(
    {
      dispatch,
      commit,
    },
    id,
  ) {
    commit('SET_MANAGER', id);
    dispatch('meetings/past/pagination/setPage', 1, {
      root: true,
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
