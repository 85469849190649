import axios from 'axios';
import { BUG_REPORT_SOURCES, type BugReport } from '@@/src/shared/api/BugReport/types';
import type { Nullable } from '@@/src/shared/typing';
import { BugReportClient } from '@@/src/shared/api/BugReport/BugReport.client';
import { usePanelApi, useRoute, useVuexStore } from '@@/src/app/plugins';

export type RawBugReport = Pick<BugReport, 'text'> & { error?: unknown };

export const useBugReportService = () => {
  const vuexStore = useVuexStore();
  const panelApi = usePanelApi();

  if (!panelApi) {
    throw new Error('[BugReportService] ошибка инициализации');
  }

  const bugReportClient = new BugReportClient(panelApi);

  const getErrorMessage = (error: unknown) => {
    if (!axios.isAxiosError(error)) {
      return;
    }

    if (error.response?.status !== 418) {
      return;
    }

    const messages: string[] | undefined = error.response?.data?.message;
    return messages?.length ? messages.join('. ') : undefined;
  };

  const filterFromEmptyValues = (report: Record<string, unknown>) => Object.fromEntries(Object.entries(report).filter(([_, value]) => Boolean(value)));
  const prepareBugReport = (bugReport: RawBugReport): BugReport => {
    const error = bugReport.error;
    const bugSource = axios.isAxiosError(error) ? BUG_REPORT_SOURCES.BACK : BUG_REPORT_SOURCES.FRONT;
    const errorText = getErrorMessage(error);
    const apiUrl = axios.isAxiosError(error) ? (error.config?.baseURL || '') + error.config.url : null;
    const requestPayload = axios.isAxiosError(error) ? error.config.data : null;
    const requestResponse = axios.isAxiosError(error) ? error.response : null;
    const rawBugReport: Nullable<BugReport> = {
      bug_source: bugSource,
      text: errorText || bugReport.text,
      manager_id: vuexStore.state.auth.user?.id,
      id_crm: vuexStore?.getters['meeting/getMeetingCrmId'],
      front_url: useRoute().fullPath,
      sentry_replay_session: process.client ? sessionStorage?.getItem('sentryReplaySession') : undefined,
      error_description: process.client ? sessionStorage?.getItem('errorDescription') : undefined,
      api_url: apiUrl,
      request_payload: requestPayload,
      request_response: requestResponse,
    };

    return filterFromEmptyValues(rawBugReport);
  };

  return {
    prepareBugReport,
    bugReportClient,
    getErrorMessage,
  };
};
