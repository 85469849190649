import { type CurrentPortraitResponse, type Portrait, usePortraitsClient } from '@@/src/shared/api/Portraits/Portraits.client';
import { DEFAULT_PORTRAIT_SLUG, usePortraitsStore } from '@@/src/shared/@pinia/use-portraits-store';

export const usePortraitsService = () => {
  const portraitsClient = usePortraitsClient();
  const portraitsStore = usePortraitsStore();

  const updatePortrait = (portrait: CurrentPortraitResponse | null) => {
    if (portrait?.slug) {
      portraitsStore.currentPortrait = portrait.slug;
    }

    return portrait;
  };

  async function getCurrentPortrait() {
    const portrait = await portraitsClient.getCurrent();
    return updatePortrait(portrait);
  }

  async function setCurrentPortrait(slug: string = DEFAULT_PORTRAIT_SLUG) {
    const portrait = await portraitsClient.setCurrent(slug);
    return updatePortrait(portrait);
  }

  const checkIsCurrentPortraitAvailable = (portraitsArray: Portrait[]) => portraitsArray.some(item => item.slug === portraitsStore.currentPortrait);

  async function getProjectPortraits(slug?: string) {
    const params: Parameters<typeof portraitsClient.getAllByProjectSlug> = slug ? [slug] : [];
    const projectPortraits = await portraitsClient.getAllByProjectSlug(...params);

    if (Array.isArray(projectPortraits)) {
      portraitsStore.currentProjectPortraits = projectPortraits;
      const isPortraitAvailable = checkIsCurrentPortraitAvailable(projectPortraits);

      if (!isPortraitAvailable) {
        portraitsStore.resetCurrentPortrait();
      }
    }

    return projectPortraits;
  }

  return {
    setCurrentPortrait,
    getCurrentPortrait,
    getProjectPortraits,
  };
};
