/* Has filter */
import { base64ToString, stringToBase64 } from '@@/src/shared/lib/utils/base64Utils';

export function splitThousands(num, separator = ' ') {
  if (num === undefined || num === null) {
    console.warn('[splitThousands] Wrong Number ', num);
    return '';
  }
  const tmp = num.toString().split('.');
  let value = tmp[0].replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  if (Number(tmp[1])) {
    value += `.${tmp[1]}`;
  }
  return value;
}

export function priceFormat(value) {
  if (Number.isNaN(value)) {
    console.warn(
      '[common/number-utils currency] "value" must be a Number!',
    );

    return;
  }

  return Number(value)
    .toLocaleString('ru-RU', {
      style: 'currency',
      currency: 'RUB',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
}

export function roundToMillions(num, accuracy = 1) {
  if (num === undefined || num === null) {
    console.warn('[roundToMillions] Wrong Number ', num);
    return '';
  }

  return (Number(num) / 1000000).toFixed(accuracy);
}

export function plural(num, postfixes) {
  if (Number.isNaN(num)) {
    console.warn('[plural] Wrong Number ', num);
    return '';
  }

  let n = Math.abs(num);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return postfixes[2];
  }
  n %= 10;
  if (n === 1) {
    return postfixes[0];
  }
  if (n >= 2 && n <= 4) {
    return postfixes[1];
  }
  return postfixes[2];
}

export function prettyPhone(rawPhoneNumber) {
  return rawPhoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3-$4-$5');
}

export function monthByNumber(num, type = 'full') {
  if (typeof num !== 'number' || Number.isNaN(num) || num < 0 || num > 11) {
    console.warn('[monthByNumber] Wrong number ', num);
    return '';
  }
  const months = {
    0: {
      full: 'Январь',
      short: 'Янв',
      case: 'Января',
    },
    1: {
      full: 'Февраль',
      short: 'Фев',
      case: 'Февраля',
    },
    2: {
      full: 'Март',
      short: 'Мар',
      case: 'Марта',
    },
    3: {
      full: 'Апрель',
      short: 'Апр',
      case: 'Апреля',
    },
    4: {
      full: 'Май',
      short: 'Май',
      case: 'Мая',
    },
    5: {
      full: 'Июнь',
      short: 'Июн',
      case: 'Июня',
    },
    6: {
      full: 'Июль',
      short: 'Июл',
      case: 'Июля',
    },
    7: {
      full: 'Август',
      short: 'Авг',
      case: 'Августа',
    },
    8: {
      full: 'Сентябрь',
      short: 'Сен',
      case: 'Сентября',
    },
    9: {
      full: 'Октябрь',
      short: 'Окт',
      case: 'Октября',
    },
    10: {
      full: 'Ноябрь',
      short: 'Ноя',
      case: 'Ноября',
    },
    11: {
      full: 'Декабрь',
      short: 'Дек',
      case: 'Декабря',
    },
  };

  return months[num][type];
}

export function dateToDaytime(date, format = 'h:m:s') {
  if (!date || !(date instanceof Date)) {
    // eslint-disable-next-line no-console
    console.log('[dateToDaytime] Wrong date, ', date);
  }

  const dic = {
    h: String(date.getHours()).padStart(2, '0'),
    m: String(date.getMinutes()).padStart(2, '0'),
    s: String(date.getSeconds()).padStart(2, '0'),
  };

  return format.split(':').map(element => element = dic[element])
    .join(':');
}

export function msToDaytime(time, format = 'h:m:s') {
  const dic = {
    d: String(Math.floor(time / (1000 * 60 * 60 * 24))),
    h: String(Math.floor(time / (1000 * 60 * 60) % 24)).padStart(2, '0'),
    m: String(Math.floor(time / 1000 / 60 % 60)).padStart(2, '0'),
    s: String(Math.floor(Math.floor(time / 1000 % 60))).padStart(2, '0'),
  };

  return format.split(':').map(element => element = dic[element])
    .join(':');
}

export function prettyDate(dateString, type = 'case') {
  const date = new Date(dateString);
  return `${date.getDate()} ${monthByNumber(date.getMonth(), type)} ${date.getFullYear()}`;
}

export function prettyDateAlter(dateString, type = 'case') {
  const date = new Date(dateString);
  return `${date.getDate()} ${monthByNumber(date.getMonth(), type)} ${dateToDaytime(date, 'h:m')}`;
}

export function lockBody() {
  const gap = `${window.innerWidth - document.documentElement.clientWidth}px`;
  document.body.style.paddingRight = gap;
  document.body.style.height = '100%';
  document.body.style.overflow = 'hidden';
  const panel = document.querySelector('.small_top_panel');
  if (panel) {
    panel.style.transition = '';
    panel.style.right = gap;

    setTimeout(() => {
      panel.style.transition = 'all 0.3s ease';
    }, 10);
  }

  const header = document.getElementById('scroll-header');
  if (!header) {
    return;
  }
  const isHalf = header.getAttribute('data-is-half');
  const isMobile = header.getAttribute('data-is-mobile');
  if (isHalf && !isMobile) {
    header.style.right = `calc(${gap} + 32rem)`;
    return;
  }
  header.style.paddingRight = gap;
}

export function unlockBody() {
  document.body.style.height = '';
  document.body.style.overflow = '';
  document.body.style.paddingRight = '';

  const panel = document.querySelector('.small_top_panel');
  if (panel) {
    panel.style.transition = '';
    panel.style.right = 0;

    setTimeout(() => {
      panel.style.transition = 'all 0.3s ease';
    }, 10);
  }

  const header = document.getElementById('scroll-header');
  if (!header) {
    return;
  }
  header.style.right = '';
  header.style.paddingRight = '';
}

export function prettyQuarter(num) {
  if (typeof num !== 'number' && !Number.isNaN(num)) {
    console.warn('[Utils/prettyQuarter] Аргумент "num" должен быть Number: ', num);
    return '';
  }

  switch (num) {
    case 1:
      return ' I';
    case 2:
      return ' II';
    case 3:
      return ' III';
    default:
      return 'IV';
  }
}

export function prettyCompletion(quarter, year, label = '') {
  const d = new Date();
  let currentQuarter = Math.floor((d.getMonth() + 3) / 3) - 1;
  let currentYear = d.getFullYear();

  if (quarter === 0) {
    currentQuarter = 4;
    currentYear -= 1;
  }

  return Number(`${currentYear}${currentQuarter}`) < Number(`${year}${quarter}`)
    ? `${label} ${prettyQuarter(quarter)} кв. ${year}`
    : 'Дом сдан'; // если нужно убрать заглавную, делать через transform: lowercase;
}

/* eslint-disable */
export function throttle(func, ms) {
    let isThrottled = false;
    let savedArgs;
    let savedThis;

    function wrapper() {

        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(function() {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

export function debounce(fn, ms) {
  let timeout;
  return function() {
    const fnCall = () => fn.apply(this, arguments);
    clearTimeout(timeout);
    timeout = setTimeout(fnCall, ms);
  };
}
/* eslint-enable */

export function getNoun(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

export function observer(block, func) {
  const io = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        func();

        observer.disconnect();
      }
    });
  });

  io.USE_MUTATION_OBSERVER = false;

  io.observe(block);

  return io;
}

export function dateToTimeString(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (hours < 10) {
    hours = `0${hours}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes}`;
}

export function getDecodedId(value) {
  const decodedValue = base64ToString(String(value));
  if (!decodedValue.includes(':')) {
    return value;
  }

  return decodedValue.split(':')[1] || '';
}

export function getDecodedNumericId(value) {
  if (Number.isFinite(Number(value))) {
    return Number(value);
  }

  return Number(getDecodedId(value));
}

/**
 * @param {number|string} sourceId
 * @param {string} entityType
 * @returns {*} any
 */
export function getEncodedId(sourceId, entityType) {
  return stringToBase64(`${entityType}:${sourceId}`);
}
