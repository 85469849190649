// Interfaces
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { AxiosResponse } from 'axios';

const GRAPHQL_URL = '/graphql/';
const ERROR_MESSAGE = 'Нет данных от API';

class BaseClient {
  #axios: NuxtAxiosInstance;

  constructor($axios: NuxtAxiosInstance) {
    this.#axios = $axios;
  }

  protected async sendRequest<ResponseType extends Record<string, any> = any>(query: any, variables?: Record<string, any>): Promise<ResponseType> {
    const response: AxiosResponse = await this.#axios.$post(GRAPHQL_URL, {
      query: query.loc?.source.body,
      variables,
    });

    if (!response.data) {
      throw new Error(ERROR_MESSAGE);
    }

    return response.data;
  }

  protected getError(methodName: string, error: any): void {
    console.error(`Ошибка при получении данных [${methodName}]: ${error.message}`);
  }
}

export { BaseClient };
