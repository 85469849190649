import { useInfraClient } from '@@/src/shared/api/Infra';
import { usePortraitsStore } from '@@/src/shared/@pinia/use-portraits-store';

export const state = () => ({
  isBackButton: false,
  infraCategories: [],
  infraCategoriesMapped: {},
  infraObjects: [],
  infraObjectsMapped: {},
});

export const actions = {
  toggleButtonView({ commit }, payload) {
    commit('SET_BUTTON_VIEW', payload);
  },

  async fetchInfraCategories({ commit, state }, payload) {
    const portraitsStore = usePortraitsStore();
    const mappedId = payload + portraitsStore.currentPortrait;
    try {
      if (mappedId in state.infraCategoriesMapped) {
        commit('SET_INFRA_CATEGORIES', state.infraCategoriesMapped[mappedId]);
        return;
      }

      const res = await useInfraClient().getCategoriesByProjectsIds([payload], portraitsStore.currentPortrait);
      commit('SET_INFRA_CATEGORIES_MAPPED', { id: mappedId, res: res?.length ? res : [] });
    }
    catch (err) {
      console.warn('[location/fetchInfraCategories] GET request failed: ', err);
    }
  },

  async fetchInfraObject({ commit, state }, payload) {
    const portraitsStore = usePortraitsStore();
    const mappedId = payload + portraitsStore.currentPortrait;
    try {
      if (mappedId in state.infraObjectsMapped) {
        commit('SET_INFRA_OBJECTS', state.infraObjectsMapped[mappedId]);
        return;
      }

      let res = await useInfraClient().getProjectObjectsBySlug(payload, portraitsStore.currentPortrait);

      if (!res?.length) {
        res = [];
      }

      res = res.filter(({ latitude, longitude }) => Boolean(latitude) && Boolean(longitude));

      commit('SET_INFRA_OBJECTS_MAPPED', { id: mappedId, res });
    }
    catch (err) {
      console.warn('[location/fetchInfraObject] GET request failed: ', err);
    }
  },

  reset({ commit }) {
    commit('SET_INFRA_CATEGORIES', []);
    commit('SET_INFRA_CATEGORIES_MAPPED', {});
    commit('SET_INFRA_OBJECTS', []);
    commit('SET_INFRA_OBJECTS_MAPPED', {});
  },
};

export const getters = {
  infraObjects(state) {
    return state.infraObjects;
  },

  /** @return {import('@@/src/shared/api/Infra').InfrastructureCategory[]} */
  infraCategories(state) {
    return state.infraCategories;
  },

  getButtonView(state) {
    return state.isBackButton;
  },
};

const getTransformedInfraObjects = (state, payload) => payload?.length
  ? payload.map((item) => {
    const category = state.infraCategories.find(category => category.id === item.category?.id);
    return {
      ...item,
      category,
      name: item.name,
      categoryName: category?.name,
    };
  }).slice()
  : [];

export const mutations = {
  SET_BUTTON_VIEW(state, payload) {
    state.isBackButton = payload;
  },

  SET_INFRA_CATEGORIES(state, payload) {
    state.infraCategories = payload?.length ? payload.slice() : [];
  },

  SET_INFRA_CATEGORIES_MAPPED(state, { id, res }) {
    state.infraCategoriesMapped[id] = res?.length ? res.slice() : [];
    state.infraCategories = res?.length ? res.slice() : [];
  },

  SET_INFRA_OBJECTS(state, payload) {
    state.infraObjects = payload;
  },

  SET_INFRA_OBJECTS_MAPPED(state, { id, res }) {
    const transformedRes = getTransformedInfraObjects(state, res);

    state.infraObjectsMapped[id] = transformedRes;
    state.infraObjects = transformedRes;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
