import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import Middleware from './middleware';
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime';

// Active schemes
import { default as Scheme$7977 } from '/app/src/app/config/common/auth/DefaultScheme.ts';
Middleware.auth = authMiddleware;
export default function (ctx, inject) {
  // Options
  var options = {
    "resetOnError": false,
    "ignoreExceptions": false,
    "scopeKey": "scope",
    "rewriteRedirects": true,
    "fullPathRedirect": false,
    "watchLoggedIn": true,
    "redirect": {
      "login": "/",
      "logout": "/",
      "home": "/",
      "callback": "/login"
    },
    "vuex": {
      "namespace": "auth"
    },
    "cookie": {
      "prefix": "auth.",
      "options": {
        "path": "/"
      }
    },
    "localStorage": false,
    "defaultStrategy": "default",
    "autoLogout": true
  };

  // Create a new Auth instance
  var $auth = new Auth(ctx, options);

  // Register strategies
  // default
  $auth.registerStrategy('default', new Scheme$7977($auth, {
    "token": {
      "property": "access",
      "maxAge": 300
    },
    "refreshToken": {
      "property": "refresh",
      "data": "refresh",
      "maxAge": 604800,
      "tokenRequired": true
    },
    "user": {
      "property": false
    },
    "cookie": {
      "name": "X-CSRFToken"
    },
    "endpoints": {
      "login": {
        "method": "post",
        "url": "api/panel/auth/token/login/"
      },
      "logout": {
        "method": "post",
        "url": "api/panel/auth/logout/"
      },
      "refresh": {
        "method": "post",
        "url": "api/panel/auth/token/refresh/"
      },
      "user": {
        "method": "get",
        "url": "api/panel/auth/"
      }
    },
    "name": "default"
  }));

  // Inject it to nuxt context as $auth
  inject('auth', $auth);
  ctx.$auth = $auth;

  // Initialize auth
  return $auth.init().catch(function (error) {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return;
      }
      console.error('[ERROR] [AUTH]', error);
    }
  });
}