function formatTimeCellString(
  number,
) {
  return String(Math.ceil(number)).padStart(2, '0');
}

function getTimezoneOffsetString(
  date,
) {
  date = new Date(date);
  const offset = date.getTimezoneOffset();

  if (offset === 0) {
    return 'Z';
  }

  const sign = offset < 0 ? '+' : '-';
  const hours = formatTimeCellString(Math.abs(offset) / 60);
  const minutes = formatTimeCellString(Math.abs(offset) % 60);

  return `${sign}${hours}:${minutes}`;
}

function toTimezoneOffsetISOString(
  date,
) {
  date = new Date(date);

  const toJSONString = date.toJSON();

  return toJSONString.replace(/Z$/m, getTimezoneOffsetString(date));
}

export {
  toTimezoneOffsetISOString,
};
