import type { AboutCategory, AboutSubcategorySlider } from '@@/src/shared/api/About/types';
import { StatefulRestClient } from '@@/src/shared/api/Base/StatefulRest.client';

export class AboutClient extends StatefulRestClient {
  CATEGORIES_LIST_ENDPOINT = '/api/panel/about/#project#/';
  SLIDERS_LIST_ENDPOINT = '/api/panel/about/#project#/#subcategory#/';

  async getCategoriesList(): Promise<AboutCategory[] | any> {
    try {
      const categories = await this.sendGetRequest<AboutCategory[]>(
        this.CATEGORIES_LIST_ENDPOINT,
        {
          project: this.getProjectSlug(),
        },
        {
          pipeline_type__name: this.getPipelineType(),
        },
      );

      return Array.isArray(categories) ? categories : [];
    }
    catch (error: any) {
      console.error('[AboutClient] getCategoriesList:', error);
      return [];
    }
  }

  async getSlidersList(subcategory: string, portrait: string): Promise<AboutSubcategorySlider[]> {
    try {
      const response = await this.sendGetRequest<AboutSubcategorySlider[]>(
        this.SLIDERS_LIST_ENDPOINT,
        {
          project: this.getProjectSlug(),
          subcategory,
        },
        {
          pipeline_type__name: this.getPipelineType(),
          portrait,
        },
      );

      return Array.isArray(response) ? response : [];
    }
    catch (error: any) {
      console.error('[AboutClient] getCategoriesList:', error);
      return [];
    }
  }
}
