import type { Pinia } from 'pinia';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { usePortalApi } from '@@/src/app/plugins';

declare module '@nuxt/types' {
  interface Context {
    $pinia: Pinia;
  }

  interface NuxtAppOptions {
    $pinia: Pinia;
  }
}

// требуется для корректной работы chessboard
export default defineNuxtPlugin(({ $pinia }) => {
  $pinia.use(() => ({
    apiInstance: usePortalApi(),
  }));
});
