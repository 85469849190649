export const state = () => ({
  meetings: undefined,
});

export const getters = {
  params(state, getters, rootState, rootGetters) {
    const params = {};

    const {
      current,
      filters,
    } = rootState.meetings;

    const page = current.pagination.page;
    const project = rootGetters['meetings/filters/activeProjectId'];
    const manager = current.filters.manager;
    const search = filters.search;

    if (page) {
      params.page = page;
    }

    if (project) {
      params.project = project;
    }

    if (manager) {
      params.manager = manager;
    }

    if (search) {
      params.q = search;
    }

    return params;
  },
};

export const mutations = {
  SET_MEETINGS(
    state,
    meetings,
  ) {
    state.meetings = meetings;
  },
};

export const actions = {
  async setMeetings(
    {
      dispatch,
      commit,
      getters,
    },
  ) {
    const {
      results,
      count,
      next,
    } = await this.$panelApi.$get(this.$routes.meetings.base, {
      params: getters.params,
    });
    commit('SET_MEETINGS', results);

    dispatch('meetings/current/pagination/setPaginationData', {
      count,
      isLastPage: !next,
    }, {
      root: true,
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
