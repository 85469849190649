import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var panelApiInstance;
var panelApiPlugin = function panelApiPlugin(ctx, inject) {
  var $config = ctx.$config,
    $axios = ctx.$axios,
    req = ctx.req;
  var API_URL;
  if ($config.IS_LOCAL_MODE) {
    API_URL = process.client ? location.origin : $config.PANEL_SITE_URL;
  } else {
    API_URL = process.client ? $config.PANEL_SITE_URL : $config.PANEL_SERVER_API_URL;
  }
  panelApiInstance = $axios.create({
    baseURL: API_URL
  });
  // region Установка заголовков
  var headers = req && req.headers ? _objectSpread({}, req.headers) : {};
  panelApiInstance.defaults.xsrfCookieName = 'csrftoken';
  panelApiInstance.defaults.xsrfHeaderName = 'X-CSRFToken';
  panelApiInstance.setHeader('X-Forwarded-Host', headers['x-forwarded-host']);
  panelApiInstance.setHeader('X-Forwarded-Port', headers['x-forwarded-port']);
  panelApiInstance.setHeader('X-Forwarded-Proto', headers['x-forwarded-proto']);
  // endregion
  inject('panelApi', panelApiInstance);
  ctx.$panelApi = panelApiInstance;
};
export default panelApiPlugin;
export var usePanelApi = function usePanelApi() {
  if (!panelApiInstance) {
    throw new Error('Panel API instance is not defined :(');
  }
  return panelApiInstance;
};