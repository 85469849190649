// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_VYlh8{font-size:3.2rem;font-weight:500}.text_sgmzH,.title_VYlh8{margin-bottom:1.6rem}.text_sgmzH{color:rgba(0,0,0,.88)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_VYlh8",
	"text": "text_sgmzH"
};
module.exports = ___CSS_LOADER_EXPORT___;
