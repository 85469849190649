import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { useProjectsService } from '@@/src/shared/lib/services/projects/Projects.service';
import { useProjectsStore } from '@@/src/shared/@pinia/use-projects-store';

export default defineNuxtMiddleware(async ({ route, redirect, $auth }) => {
  // Получение проектов только при наличии авторизации
  if (!$auth.loggedIn) {
    return;
  }
  // Перешел ли пользователь по прямой ссылке на страницу списков встреч
  // уже с готовой встречей в параметрах
  const hasDirectLink = route.name === 'project-slug-meeting-index-list' && route.query.meeting;

  const projectsService = useProjectsService();
  const projectsStore = useProjectsStore();
  const routeSlug = route.params.slug;

  if (hasDirectLink) {
    // Установка активного жк с ссылки
    await projectsService.setUserActiveProject(routeSlug);
  }

  await projectsService.getAllProjects();
  await projectsService.getUserActiveProject();

  const projectSlug = projectsStore.activeProjectSlug;

  // Если в роуте слаг проекта отличается от текущего с бэка, провести редирект
  if (projectSlug && routeSlug && projectSlug !== routeSlug) {
    // FIXME почему-то в стейдже ts указывает, что такой перегрузки нет, хотя редирект работает как надо
    redirect({
      name: route.name || 'project-slug-meeting-index',
      params: {
        slug: projectSlug,
      },
    });
  }
});
