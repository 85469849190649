var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p-scrollable-container",
    class: _vm.classes,
    style: _vm.styles
  }, [_vm.fadeTop ? _c('div', {
    staticClass: "p-scrollable-container__fade-top"
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "p-scrollable-container-inner",
    attrs: {
      "data-scrollable-container": ""
    }
  }, [_c('div', {
    staticClass: "p-scrollable-container__content"
  }, [_vm._t("default"), _vm._v(" "), _vm.fadeBottom ? _c('div', {
    staticClass: "p-scrollable-container__fade-bottom-spacer"
  }) : _vm._e()], 2)]), _vm._v(" "), _vm.fadeBottom ? _c('div', {
    staticClass: "p-scrollable-container__fade-bottom"
  }) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };