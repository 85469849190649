export const state = () => ({
  previousPage: '',
});

export const getters = {};

export const actions = {
  setPreviousPage({ commit }, payload) {
    commit('SET_PREVIOUS_PAGE', payload);
  },
};

export const mutations = {
  SET_PREVIOUS_PAGE(state, payload) {
    state.previousPage = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
