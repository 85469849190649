var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SModal, {
    staticClass: "portrait-modal",
    attrs: {
      "modal-key": "portrait-pick-modal",
      "theme": "white-blue-center",
      "type": "center-popup"
    },
    on: {
      "close": function close($event) {
        return _setup.modalsStore.closePortraitModal();
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('p', {
          staticClass: "text-business-l portrait-modal__title"
        }, [_vm._v("\n      Выбрать портрет\n    ")])];
      },
      proxy: true
    }, {
      key: "bottom",
      fn: function fn() {
        return [_c(_setup.SButton, {
          staticClass: "portrait-modal__action-button",
          on: {
            "click": _setup.onActionButtonClick
          }
        }, [_vm._v("\n      Готово\n    ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c('div', {
    staticClass: "portrait-modal__content"
  }, [_c(_setup.SSelect, {
    attrs: {
      "z-index": "1000",
      "label": "Портрет",
      "theme": "secondary"
    },
    model: {
      value: _setup.newPortrait,
      callback: function callback($$v) {
        _setup.newPortrait = $$v;
      },
      expression: "newPortrait"
    }
  }, _vm._l(_setup.preparedOptions, function (option) {
    return _c(_setup.SOption, {
      key: option.id,
      attrs: {
        "value": option.value,
        "label": option.text
      }
    }, [_vm._v("\n        " + _vm._s(option.text) + "\n      ")]);
  }), 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };