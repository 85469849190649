import type {
  ChoiceSpecType,
  LotFacet,
  LotFacets,
  RangeSpecType,
  SerializedSpecs,
} from '@@/src/shared/api/Lot/types';

export function serializeFacets(rawFacets: LotFacet[]): LotFacets {
  const facets: LotFacets = { building: [] };
  if (rawFacets && rawFacets.length) {
    rawFacets.forEach((facet) => {
      facets[facet.name] = facet.choices || facet.range || [];
    });
  }
  return facets;
}

export function specsArrayToObject(specs: (RangeSpecType | ChoiceSpecType)[]): SerializedSpecs {
  const moddedSpecs: SerializedSpecs = { building: [] };
  if (specs.length) {
    specs.forEach((el: RangeSpecType | ChoiceSpecType): void => {
      if ('choices' in el) {
        moddedSpecs[el.name] = el.choices;
      }
      else {
        moddedSpecs[el.name] = el.range;
      }
    });
  }

  return moddedSpecs;
}
