import type { Context } from '@nuxt/types';
import { tryToAddErrorDescription } from './errorDescriptions.utils';

class ErrorLoggerService {
  private readonly logger: Context['$sentry'];

  constructor(sentry: Context['$sentry']) {
    this.logger = sentry;
  }

  log(error: unknown) {
    tryToAddErrorDescription(error);
    this.logger.captureException(error);
  }
}

let errorLoggerService: ErrorLoggerService | undefined;
export const useErrorLoggerService = (logger: Context['$sentry']) => {
  if (!errorLoggerService) {
    errorLoggerService = new ErrorLoggerService(logger);
  }

  return errorLoggerService;
};
