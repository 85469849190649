import camelCase from 'lodash/camelCase';

type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}` ? `${T}${Capitalize<SnakeToCamelCase<U>>}` : S;

export type ConvertSnakeCaseToCamelCase<T> = {
  [K in keyof T as SnakeToCamelCase<K & string>]: T[K] extends object ? ConvertSnakeCaseToCamelCase<T[K]> : T[K]
};

function isObject(value: unknown): value is Record<string, unknown> {
  return Boolean(value && typeof value === 'object' && !Array.isArray(value));
}

export function convertObjectKeys<T extends Record<string, unknown>>(obj: T): ConvertSnakeCaseToCamelCase<T> {
  if (!isObject(obj)) {
    return obj;
  }

  const result: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(obj)) {
    const newKey = camelCase(key);

    if (isObject(value)) {
      result[newKey] = convertObjectKeys(value);
    }
    else if (Array.isArray(value)) {
      result[newKey] = value.map(convertObjectKeys);
    }
    else {
      result[newKey] = value;
    }
  }
  return result as ConvertSnakeCaseToCamelCase<T>;
}
