/**
 * @see https://stackoverflow.com/questions/69059693/nuxt-auth-check-if-route-is-protected
 *
 * @param route
 * @param key
 * @param value
 * @returns {boolean} boolean
 */
export function routeOption(
  route,
  key,
  value,
) {
  return route.matched.some((m) => {
    if (process.client) {
      return Object
        .values(m.components)
        .some(component => component.options && component.options[key] === value);
    }
    else {
      return Object
        .values(m.components)
        .some(component => Object.values(component._Ctor).some(ctor => ctor.options && ctor.options[key] === value));
    }
  });
}

/**
 * Защищена ли страница авторизацией
 * (проверяет наличие `auth: false` в параметрах страницы)
 *
 * @param route
 * @returns {boolean} boolean
 */
export function isAuthRequiredOnRoute(route) {
  return !routeOption(route, 'auth', false);
}

/**
 * Доступна ли на странице логика проекта
 * (проверяет наличие `project: false` в параметрах страницы)
 *
 * @param route
 * @returns {boolean} boolean
 */
export function isProjectAvailableOnRoute(route) {
  return isAuthRequiredOnRoute(route) && !routeOption(route, 'project', false);
}

/**
 * Доступна ли на странице логика встречи
 * (проверяет наличие `meeting: false` в параметрах страницы)
 *
 * @param route
 * @returns {boolean} boolean
 */
export function isMeetingAvailableOnRoute(route) {
  return isAuthRequiredOnRoute(route) && !routeOption(route, 'meeting', false);
}
