import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SMortgageAxiosConfig } from '@strana-artw/mortgage';
var servicesApiInstance;
var servicesApiPlugin = function servicesApiPlugin(ctx, inject) {
  var $axios = ctx.$axios,
    $config = ctx.$config;
  var API_URL = $config.SERVICES_SITE_URL;
  var API_PATH = '/api/v1';
  if ($config.IS_LOCAL_MODE && process.client) {
    API_URL = location.origin;
    API_PATH = "/services".concat(API_PATH);
  }
  API_URL += API_PATH;
  servicesApiInstance = $axios.create(_objectSpread(_objectSpread({}, SMortgageAxiosConfig), {}, {
    baseURL: API_URL
  }));
  inject('servicesApi', servicesApiInstance);
  ctx.$servicesApi = servicesApiInstance;
};
export default servicesApiPlugin;
export var useServicesApi = function useServicesApi() {
  return servicesApiInstance;
};