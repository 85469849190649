// Interfaces
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { AxiosResponse } from 'axios';
import type { Awaited } from '@vueuse/core';
import type { SendLotPdfStatisticPayload } from '../Offer/Offer.client';

const ERROR_MESSAGE = 'Нет данных от API';

class RestClient {
  #apiInstance: NuxtAxiosInstance;

  constructor($apiInstance: NuxtAxiosInstance) {
    this.#apiInstance = $apiInstance;
  }

  replacePathAliases(
    path: string,
    aliases: Record<string, string | number>,
    aliasToPlaceholder: (alias: string) => string = (alias: string) => `#${alias}#`,
  ) {
    let alias;
    for (alias in aliases) {
      if (!(alias in aliases)) {
        continue;
      }
      const placeholder = aliasToPlaceholder(alias);
      const replace = aliases[alias];
      path = path.replaceAll(placeholder, String(replace));
    }

    return path;
  }

  protected async sendGetRequest<ResponseType>(
    url: string,
    aliases: Record<string, string | number> = {},
    query: Record<string, unknown> = {},
  ): Promise<ResponseType> {
    const response: Awaited<ResponseType> = await this.#apiInstance.$get<ResponseType>(
      this.replacePathAliases(url, aliases),
      { params: query },
    );

    if (!response) {
      throw new Error(ERROR_MESSAGE);
    }

    return response;
  }

  protected async sendPostRequest<ResponseType = AxiosResponse>(
    url: string,
    // TODO: явно body должен передаваться раньше алиасов
    aliases: Record<string, string | number> = {},
    body: SendLotPdfStatisticPayload | Record<string, unknown> | null = null,
    query: Record<string, unknown> = {},
  ): Promise<ResponseType> {
    return this.#apiInstance.$post<ResponseType>(
      this.replacePathAliases(url, aliases),
      body,
      { params: query },
    );
  }

  protected displayError(methodName: string, error: any): void {
    console.error(`Ошибка при получении данных [${methodName}]: ${error.message}`);
  }
}

export { RestClient };
