var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "global-modals"
  }, [_setup.statusModal.state ? _c(_setup.PStatusBaseModal, {
    staticClass: "global-status-modal",
    attrs: {
      "modal-props": _setup.statusModalProps
    },
    on: {
      "close": _setup.hideStatusModal
    },
    scopedSlots: _vm._u([_setup.statusModal.button ? {
      key: "bottom",
      fn: function fn() {
        var _setup$statusModal$bu, _setup$statusModal$bu3;
        return [_c('s-button', _vm._g(_vm._b({
          staticClass: "p-status-base-modal-bottom-btn p-status-base-modal-bottom-btn--wide",
          scopedSlots: _vm._u([(_setup$statusModal$bu = _setup.statusModal.button) !== null && _setup$statusModal$bu !== void 0 && _setup$statusModal$bu.icon ? {
            key: "after",
            fn: function fn() {
              var _setup$statusModal$bu2;
              return [_c('s-icon', {
                attrs: {
                  "icon": (_setup$statusModal$bu2 = _setup.statusModal.button) === null || _setup$statusModal$bu2 === void 0 ? void 0 : _setup$statusModal$bu2.icon,
                  "size": "14"
                }
              })];
            },
            proxy: true
          } : null], null, true)
        }, 's-button', _setup.modalButtonBinds, false), _setup.modalButtonClickHandler ? {
          click: _setup.modalButtonClickHandler
        } : {}), [_vm._v("\n        " + _vm._s((_setup$statusModal$bu3 = _setup.statusModal.button) === null || _setup$statusModal$bu3 === void 0 ? void 0 : _setup$statusModal$bu3.text) + "\n\n        ")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_setup.statusModal.content)
    }
  })]) : _vm._e(), _vm._v(" "), _setup.modalsStore.portraitModal.state ? _c(_setup.PortraitPickModal, {
    attrs: {
      "options": _setup.modalsStore.portraitModal.options
    },
    on: {
      "close": _setup.modalsStore.closePortraitModal
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };