import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.function.name.js";
import Vue from 'vue';
import { createImage } from '~image';
import NuxtImg from '~image/components/nuxt-img.vue';
import NuxtPicture from '~image/components/nuxt-picture.vue';
import * as staticRuntime$34c2 from '/app/node_modules/@nuxt/image/dist/runtime/providers/static.js';
import * as ipxRuntime$7eb5 from '/app/node_modules/@nuxt/image/dist/runtime/providers/ipx.js';
var imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {}
};
imageOptions.providers = _defineProperty(_defineProperty({}, 'static', {
  provider: staticRuntime$34c2,
  defaults: {}
}), 'ipx', {
  provider: ipxRuntime$7eb5,
  defaults: {}
});
Vue.component(NuxtImg.name, NuxtImg);
Vue.component(NuxtPicture.name, NuxtPicture);
Vue.component('NImg', NuxtImg);
Vue.component('NPicture', NuxtPicture);
export default function (nuxtContext, inject) {
  var $img = createImage(imageOptions, nuxtContext);
  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(function (_ref) {
      var nuxtState = _ref.nuxtState;
      var ssrData = nuxtState.data[0] || {};
      ssrData._img = nuxtState._img || {};
    });
  }
  inject('img', $img);
}