import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.number.constructor.js";
export default {
  name: 'Er404',
  props: {
    flatsCount: {
      type: Number,
      default: null
    }
  }
};