import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
export default {
  name: 'PInputRange',
  props: {
    /**
     * Название элемента формы. Если передано, используется для события `input:keyed`
     */
    name: {
      type: String,
      default: ''
    },
    /**
     * Изменяет размер (высоту) компонента
     */
    size: {
      type: String,
      default: 'default'
    },
    /**
     * Изменяет цветовую схему компонента
     */
    theme: {
      type: String,
      default: 'primary'
    },
    /**
     * Заголовок поля
     */
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      required: true
    },
    /**
     * Минимальное значение
     */
    min: {
      type: Number,
      default: 0
    },
    /**
     * Максимальное значение
     */
    max: {
      type: Number,
      default: 100
    },
    /**
     * Шаг смещения ползунка
     */
    step: {
      type: Number,
      default: 1
    },
    /**
     * Состояние недоступности кнопки
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Разделяет введенное число по тысячным (1 000 000) в SInputRangeField
     */
    splitThousands: {
      type: Boolean,
      default: false
    },
    /**
     * Форматировать отображаемое значение согласно настройкам локали (например, `ru-RU`).
     * Не форматировать, если пустое значение.
     */
    locale: {
      type: String,
      default: 'ru-RU'
    }
  },
  emits: ['input', 'input:keyed'],
  methods: {
    handleInput: function handleInput(value) {
      this.$emit('input', value);
      if (this.name) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('input:keyed', _defineProperty({}, this.name, value));
      }
    }
  }
};