import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
export default {
  name: 'PSelectFilterField',
  props: {
    theme: {
      type: String,
      default: 'secondary'
    },
    size: {
      type: String,
      default: 'default'
    },
    display: {
      type: String,
      default: 'input'
    },
    zIndex: {
      type: [Number, String],
      default: 500,
      validator: function validator(value) {
        return !Number.isNaN(Number(value));
      }
    },
    label: {
      type: String,
      default: ''
    },
    labelName: {
      type: String,
      default: 'label'
    },
    valueName: {
      type: String,
      default: 'value'
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String, Array],
      default: ''
    },
    ownedSpec: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    spec: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    facet: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isValueAny: {
      type: Boolean,
      default: false
    },
    valueAnyLabel: {
      type: String,
      default: 'Любой'
    },
    /**
     * То, что будет передано в emit если ничего не выбрано
     */
    emptyValue: {
      type: [String, Number, Array],
      default: null
    }
  },
  computed: {
    isFacetsExist: function isFacetsExist() {
      return Array.isArray(this.facet);
    },
    computedValue: function computedValue() {
      if (Array.isArray(this.value) && !this.value.length) {
        return '';
      }
      return this.value;
    },
    availableOptions: function availableOptions() {
      var _this$ownedSpec,
        _this = this;
      if ((_this$ownedSpec = this.ownedSpec) !== null && _this$ownedSpec !== void 0 && _this$ownedSpec.length) {
        return this.spec.filter(function (option) {
          return _this.ownedSpec.includes(option.value);
        });
      }
      return this.spec;
    },
    isSelectDisabled: function isSelectDisabled() {
      if (this.loading || this.disabled) {
        return true;
      }
      return this.loading || this.disabled || this.availableOptions.length < 2 || this.isFacetsExist && !this.facet.length;
    }
  },
  methods: {
    handleChange: function handleChange(value) {
      if (this.multiple && Array.isArray(value) && value[0] === '') {
        // Обработка случая ['']
        this.$emit('change', this.emptyValue);
      } else {
        this.$emit('change', value);
      }
    },
    getOptionValue: function getOptionValue(option) {
      return option === null || option === void 0 ? void 0 : option[this.valueName];
    },
    checkDisabledOption: function checkDisabledOption(option) {
      if (!this.isFacetsExist) {
        return;
      }
      var value = this.getOptionValue(option);
      return this.facet && !this.facet.includes(value);
    },
    getOptionLabel: function getOptionLabel(option) {
      return option === null || option === void 0 ? void 0 : option[this.labelName];
    }
  }
};