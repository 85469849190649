import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
export var useFlatsStore = definePiniaStore('flats-store', function () {
  var savedValues = ref({});
  var visualValues = ref({});
  var bookedLots = ref([]);
  function setSavedValues(payload) {
    savedValues.value = payload;
  }
  function reset() {
    savedValues.value = {};
    visualValues.value = {};
    bookedLots.value = [];
  }
  function setVisualValues(payload) {
    visualValues.value = payload;
  }
  function resetVisualValues() {
    visualValues.value = {};
  }
  function resetSavedValues() {
    savedValues.value = {};
  }
  function saveBookedLot(payload) {
    if (bookedLots.value.includes(payload)) {
      return;
    }
    bookedLots.value.push(payload);
  }
  return {
    savedValues: savedValues,
    visualValues: visualValues,
    bookedLots: bookedLots,
    setSavedValues: setSavedValues,
    reset: reset,
    setVisualValues: setVisualValues,
    resetVisualValues: resetVisualValues,
    resetSavedValues: resetSavedValues,
    saveBookedLot: saveBookedLot
  };
});