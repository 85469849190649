function getErrorDescriptions(): Error[] {
  try {
    const sessionStorageValue = sessionStorage.getItem('errorDescription');
    if (!sessionStorageValue) {
      return [];
    }

    const parsedValue = JSON.parse(sessionStorageValue);

    if (!Array.isArray(parsedValue)) {
      console.error('Stored value is not an array');
      return [];
    }

    return parsedValue;
  }
  catch (error) {
    console.error('Error parsing sessionStorage value:', error);
    return [];
  }
}

export function tryToAddErrorDescription(error: unknown) {
  if (!process.client) {
    return;
  }

  const oldValue = getErrorDescriptions();
  const newValue = [...oldValue, error];
  const stringValue = JSON.stringify(newValue, Object.getOwnPropertyNames(error));
  sessionStorage?.setItem('errorDescription', stringValue);
}
