var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrapper has-sidebar",
    class: [{
      'has-sidebar--closed': !_vm.sidebarStore.isSidebar,
      'has-sidebar--overlay': _vm.isSidebarOverlay,
      'has-sidebar--lg-overlay': _vm.isSidebarLgOverlay
    }],
    attrs: {
      "id": "app"
    }
  }, [_c('TheMenu', {
    staticClass: "menu"
  }), _vm._v(" "), _c('main', {
    staticClass: "main"
  }, [_c('nuxt', {
    key: _vm.meetingId,
    attrs: {
      "keep-alive": "",
      "keep-alive-props": _vm.keepAliveProps
    }
  })], 1), _vm._v(" "), _c('client-only', [_c('GlobalModals'), _vm._v(" "), _c('portal-target', {
    attrs: {
      "name": "layout",
      "multiple": ""
    }
  })], 1), _vm._v(" "), _c('TheModal')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };