export enum BUG_REPORT_SOURCES {
  FRONT = 'FRONT',
  BACK = 'BACK',
}

export type BugReport = Partial<{
  manager_id: number;
  id_crm: number;
  bug_source: BUG_REPORT_SOURCES;
  text: string;
  error_description: string;
  sentry_replay_session: string;
  front_url: string;
  api_url: string;
  request_payload: string;
  request_response: unknown;
}>;
