var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.container
  }, [_c('h1', {
    staticClass: "h2",
    class: _vm.$style.title
  }, [_vm._v("\n    Ведутся технические работы\n  ")]), _vm._v(" "), _c('s-button', {
    staticClass: "btn",
    class: _vm.$style.btn,
    on: {
      "click": _vm.goHome
    }
  }, [_vm._v("\n    Вернуться на главную\n  ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };