export const state = () => ({
  isSuperUser: false,
  isNoMeetingsAccess: false,
  isNoMeetingsHistory: false,
});

export const getters = {
  isSuperUser(state) {
    return state.isSuperUser;
  },

  isNoMeetingsAccess(state) {
    return state.isNoMeetingsAccess;
  },

  isNoMeetingsHistory(state) {
    return state.isNoMeetingsHistory;
  },
};

export const actions = {
  setIsSuperUser({ commit }, payload) {
    commit('SET_IS_SUPER_USER', payload);
  },

  setIsNoMeetingsAccess({ commit }, payload) {
    commit('SET_IS_NO_MEETINGS_ACCESS', payload);
  },

  setIsNoMeetingsHistory({ commit }, payload) {
    commit('SET_IS_NO_MEETING_HISTORY', payload);
  },
};

export const mutations = {
  SET_IS_SUPER_USER(state, payload) {
    state.isSuperUser = payload;
  },

  SET_IS_NO_MEETINGS_ACCESS(state, payload) {
    state.isNoMeetingsAccess = payload;
  },

  SET_IS_NO_MEETING_HISTORY(state, payload) {
    state.isNoMeetingsHistory = payload;
  },
};
