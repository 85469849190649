import "core-js/modules/web.timers.js";
var MENU_TRANSITION_DURATION = 300;
export var useSidebarStore = definePiniaStore('sidebar-store', function () {
  // State
  var isSidebar = ref(true);
  var isSidebarVisible = ref(true);
  var navItems = ref([]);
  var isManuallyClosed = ref(false);
  // Getters
  var isSidebarOpening = computed(function () {
    return isSidebar.value && isSidebar.value !== isSidebarVisible.value;
  });
  var isSidebarClosing = computed(function () {
    return !isSidebar.value && isSidebar.value !== isSidebarVisible.value;
  });
  var getNavItems = computed(function () {
    return navItems.value;
  });
  // Actions
  function toggleSidebar() {
    isSidebar.value = !isSidebar.value;
    setTimeout(function () {
      toggleSidebarVisible();
    }, MENU_TRANSITION_DURATION);
    isManuallyClosed.value = !isSidebar.value;
  }
  function toggleSidebarVisible() {
    isSidebarVisible.value = isSidebar.value;
    if (process.client && window) {
      window.dispatchEvent(new Event('resize'));
    }
  }
  function setSidebar(flag) {
    isSidebar.value = flag;
  }
  function setNavItems(payload) {
    if (Array.isArray(payload)) {
      navItems.value = payload;
    }
  }
  return {
    // State
    isSidebar: isSidebar,
    isSidebarVisible: isSidebarVisible,
    navItems: navItems,
    isManuallyClosed: isManuallyClosed,
    // Getters
    isSidebarOpening: isSidebarOpening,
    isSidebarClosing: isSidebarClosing,
    getNavItems: getNavItems,
    // Actions
    toggleSidebar: toggleSidebar,
    toggleSidebarVisible: toggleSidebarVisible,
    setSidebar: setSidebar,
    setNavItems: setNavItems
  };
});