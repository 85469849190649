import { useProjectsStore } from '@@/src/shared/@pinia/use-projects-store';

export const state = () => (
  {
    project: undefined,
    search: undefined,
  });

export const getters = {
  activeProjectId(state) {
    const projectsStore = useProjectsStore();
    const projects = projectsStore.projectsList || [];
    const projectSlug = state.project || projectsStore.activeProjectSlug;
    const filterProject = projects.find(({ slug }) => slug === projectSlug) || null;
    return filterProject?.pk;
  },
};

export const mutations = {
  SET_PROJECT(state, project) {
    state.project = project;
  },

  SET_SEARCH(state, search) {
    state.search = search;
  },
};

export const actions = {
  setProject({ commit }, project) {
    commit('SET_PROJECT', project);
  },

  async updateProject(
    {
      dispatch,
      commit,
    },
    project,
  ) {
    commit('SET_PROJECT', project);
    await dispatch('updateTables');
  },

  async updateSearch(
    {
      dispatch,
      commit,
    },
    text,
  ) {
    commit('SET_SEARCH', text);
    await dispatch('updateTables');
  },

  async updateTables({ dispatch }) {
    await Promise.all([
      dispatch('meetings/upcoming/pagination/setPage', 1, {
        root: true,
      }),
      dispatch('meetings/past/pagination/setPage', 1, {
        root: true,
      }),
      dispatch('meetings/current/pagination/setPage', 1, {
        root: true,
      }),
    ]);
  },

  setDefaultValues({ dispatch }) {
    dispatch('setProject', useProjectsStore().activeProjectSlug);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
