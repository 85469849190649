import { useUserProjectClient } from '@@/src/shared/api/UserProject';
import { useProjectsStore } from '@@/src/shared/@pinia/use-projects-store';
import { useProjectClient } from '@@/src/shared/api/Project';

export const useProjectsService = () => {
  const projectsClient = useProjectClient();
  const userProjectClient = useUserProjectClient();
  const projectsStore = useProjectsStore();

  async function getAllProjects() {
    if (projectsStore.projectsList?.length) {
      return;
    }

    projectsStore.projectsList = await projectsClient.getGlobalList({ showInPanelManager: true });
  }

  async function setUserActiveProject(slug: string) {
    if (!slug) {
      return;
    }

    await userProjectClient.setCurrent(slug);
    projectsStore.setActiveProjectBySlug(slug);
  }

  async function getUserActiveProject() {
    const response = await userProjectClient.getCurrent();
    const slug = response?.slug || projectsStore.projectsList?.[0].slug;

    if (!slug) {
      return;
    }

    projectsStore.setActiveProjectBySlug(slug);

    return slug;
  }

  return {
    getAllProjects,
    setUserActiveProject,
    getUserActiveProject,
  };
};
