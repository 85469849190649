// Interfaces
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { Store } from 'vuex';
import type { PipelineType } from '@@/src/shared/api/Meeting/types';
import { useProjectsStore } from '@@/src/shared/@pinia/use-projects-store';
import { RestClient } from './Rest.client';

export class StatefulRestClient extends RestClient {
  vuexStore: Store<any>;

  constructor($apiInstance: NuxtAxiosInstance, $store: Store<any>) {
    super($apiInstance);

    this.vuexStore = $store;
  }

  protected getProjectSlug(): string {
    return String(useProjectsStore().activeProjectSlug || '');
  }

  protected getPipelineType(): PipelineType {
    return this.vuexStore.getters['meeting/getPipeline'];
  }

  protected getMeetingId() {
    return this.vuexStore.getters['meeting/getMeetingId'] || '';
  }
}
