var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('PInputInterval', _vm._b({
    on: {
      "input": _vm.handleInput
    }
  }, 'PInputInterval', _vm.computedInputIntervalProps, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };