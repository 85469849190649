export const state = () => ({
  currentManagers: undefined,
  upcomingManagers: undefined,
  pastManagers: undefined,
});

export const mutations = {
  SET_CURRENT_MANAGERS(state, managers) {
    state.currentManagers = managers;
  },

  SET_UPCOMING_MANAGERS(state, managers) {
    state.upcomingManagers = managers;
  },

  SET_PAST_MANAGERS(state, managers) {
    state.pastManagers = managers;
  },
};

export const actions = {
  async getManagers({
    commit,
  }) {
    const fetch = await this.$panelApi.$get(this.$routes.meetings.specs);
    let managers = [];
    let upcomingManagers = [];
    let pastManagers = [];

    fetch.forEach((item) => {
      if (item.name === 'manager') {
        managers = item.choices || [];
      }

      if (item.name === 'manager_upcoming') {
        upcomingManagers = item.choices || [];
      }

      if (item.name === 'manager_missed') {
        pastManagers = item.choices || [];
      }
    });

    commit('SET_CURRENT_MANAGERS', managers);
    commit('SET_UPCOMING_MANAGERS', upcomingManagers);
    commit('SET_PAST_MANAGERS', pastManagers);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
