import { defineNuxtPlugin, nextTick } from '@nuxtjs/composition-api';

export default defineNuxtPlugin(({ $auth, $scopedAccess }) => {
  if ($auth.user?.scopes && $scopedAccess) {
    $scopedAccess.setScopes();
  }

  $auth.$storage.watchState('user', async (user) => {
    if (user && $scopedAccess) {
      await nextTick();
      $scopedAccess.setScopes();
    }
  });
});
