const middleware = {}

middleware['auth-guard'] = require('../src/middleware/auth-guard.ts')
middleware['auth-guard'] = middleware['auth-guard'].default || middleware['auth-guard']

middleware['meeting'] = require('../src/middleware/meeting.ts')
middleware['meeting'] = middleware['meeting'].default || middleware['meeting']

middleware['navigation'] = require('../src/middleware/navigation.ts')
middleware['navigation'] = middleware['navigation'].default || middleware['navigation']

middleware['projects'] = require('../src/middleware/projects.ts')
middleware['projects'] = middleware['projects'].default || middleware['projects']

export default middleware
