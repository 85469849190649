/**
 * Сохранение чувствительного к текущему пользователю параметра в cookies.
 */
export const setUserNamespacedCookie = ($cookies, cookieName, value, userId) => {
  if (!userId) {
    return;
  }

  const storedValues = $cookies.get(cookieName);
  $cookies.set(cookieName, {
    ...(typeof storedValues === 'object' && storedValues),
    [userId]: value,
  });
};

/**
 * Получение чувствительного к текущему пользователю параметра из cookies.
 */
export const getUserNamespacedCookie = ($cookies, cookieName, userId) => {
  if (!userId) {
    return;
  }

  const storedValues = $cookies.get(cookieName) || {};

  if (typeof storedValues !== 'object') {
    return null;
  }

  return storedValues[userId] || null;
};

/**
 * Удаление чувствительного к текущему пользователю параметра из cookies.
 */
export const removeUserNamespacedCookie = ($cookies, cookieName, userId) => {
  if (!userId) {
    return;
  }

  const storedValues = $cookies.get(cookieName) || null;
  if (!storedValues) {
    return;
  }

  if (typeof storedValues === 'object' && Object.prototype.hasOwnProperty.call(storedValues, userId)) {
    delete storedValues[userId];
  }

  if (Object.keys(storedValues).length < 1) {
    $cookies.remove(cookieName);
    return;
  }

  $cookies.set(cookieName, storedValues);
};
