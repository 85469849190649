import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
export default {
  name: 'FilterContainer',
  props: {
    label: {
      type: String,
      default: ''
    },
    postfix: {
      type: String,
      default: ''
    }
  }
};