// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".print_3i5Vx{background-color:#fff;display:block;font-family:\"Mazzard\",\"Arial\",sans-serif;page-break-before:always;-webkit-print-color-adjust:exact;print-color-adjust:exact;-webkit-column-break-before:page;-moz-column-break-before:page;break-before:page}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"print": "print_3i5Vx"
};
module.exports = ___CSS_LOADER_EXPORT___;
