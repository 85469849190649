import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { isAuthRequiredOnRoute, routeOption } from '@@/src/shared/lib/helpers/route/routeOption';
import { useSidebarStore } from '../shared/@pinia';

export default defineNuxtMiddleware(async ({ route, redirect, $navigation, $auth }) => {
  const sidebarStore = useSidebarStore();

  const isSidebarOverlay = routeOption(route, 'sidebarOverlay', true);
  if (isSidebarOverlay || !sidebarStore.isManuallyClosed) {
    sidebarStore.setSidebar(!isSidebarOverlay);
  }

  if (
    !$auth.loggedIn
    || !isAuthRequiredOnRoute(route)
  ) {
    return;
  }

  // Обновить текущий роут в плагине навигации для корректного формирования меню
  $navigation.setRoute(route);

  // Если активный пункт меню отсутствует или отключен,
  // перенаправить на главную страницу
  const activeMenuItem = $navigation.getActiveMenuItem();

  // TODO: когда-то полностью типизировать
  // @ts-expect-error когда-то полностью типизировать
  if (!activeMenuItem || activeMenuItem?.disabled) {
    const homeMenuItem = $navigation.getHomeMenuItem();
    // @ts-expect-error когда-то полностью типизировать
    if (homeMenuItem && homeMenuItem.to.name !== route.name) {
      // @ts-expect-error когда-то полностью типизировать
      return redirect(homeMenuItem.to);
    }
  }

  await $navigation.saveNavItemsToStore();
});
