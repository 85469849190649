export const state = () => (
  {
    page: 0,
    count: undefined,
    isLastPage: undefined,
  }
);

export const mutations = {
  SET_PAGE(
    state,
    page,
  ) {
    state.page = page;
  },

  SET_COUNT(
    state,
    count,
  ) {
    state.count = count;
  },

  SET_LAST_PAGE(
    state,
    flag,
  ) {
    state.isLastPage = flag;
  },
};

export const actions = {
  async setPage(
    {
      commit,
      dispatch,
    },
    page,
  ) {
    commit('SET_PAGE', page);

    await dispatch('meetings/current/setMeetings', undefined, {
      root: true,
    });
  },

  setPaginationData(
    {
      commit,
    }, {
      count,
      isLastPage,
    },
  ) {
    commit('SET_COUNT', count);
    commit('SET_LAST_PAGE', isLastPage);
  },
};

export default {
  state,
  mutations,
  actions,
};
