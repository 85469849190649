import type { AxiosResponse } from 'axios';
import { RestClient } from '@@/src/shared/api/Base/Rest.client';
import type { BugReport } from '@@/src/shared/api/BugReport/types';

export class BugReportClient extends RestClient {
  private BUG_REPORT_ENDPOINT = 'api/panel/bugs_report/';

  // Ответ есть, но нигде пока что не используется
  async sendBugReport(bugReport: BugReport): Promise<AxiosResponse> {
    return this.sendPostRequest(this.BUG_REPORT_ENDPOINT, {}, bugReport);
  }
}
