export const state = () => ({
  meeting: {}, // Собирает значения по шагам перед одним общим updateMeeting
});

export const getters = {
  getMeeting(state) {
    return state.meeting;
  },
};

export const mutations = {
  SET_MEETING(state, payload) {
    state.meeting = payload;
  },
};

export const actions = {
  clearSurveyMeeting({
    commit,
  }) {
    commit('SET_MEETING', {});
  },

  updateSurveyMeeting({
    commit,
    state,
  }, payload) {
    const newResult = {
      ...state.meeting,
      ...payload,
    };
    commit('SET_MEETING', newResult);
  },

};
