import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.includes.js";
var THEMES = ['primary', 'panel'];
var GroupTheme = {
  PRIMARY: 'primary',
  PANEL: 'panel-white'
};
var ChipTheme = {
  PRIMARY: 'primary-common-filter',
  PANEL: 'panel-white-filter'
};
export default {
  name: 'PButtonFilterField',
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    spec: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    facet: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    values: {
      type: [Array, String],
      default: function _default() {
        return [];
      }
    },
    theme: {
      type: String,
      default: THEMES[0],
      validator: function validator(value) {
        return THEMES.includes(value);
      }
    }
  },
  computed: {
    groupTheme: function groupTheme() {
      return GroupTheme[this.theme.toUpperCase()];
    },
    chipTheme: function chipTheme() {
      return ChipTheme[this.theme.toUpperCase()];
    }
  }
};