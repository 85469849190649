import "core-js/modules/es.object.to-string.js";
export var DEFAULT_PORTRAIT_SLUG = 'default';
export var PAGES_WITH_PORTRAITS = ['project-slug-about', 'project-slug-location'];
export var usePortraitsStore = definePiniaStore('portraits-store', function () {
  var currentPortrait = ref(DEFAULT_PORTRAIT_SLUG);
  var currentProjectPortraits = ref(null);
  var resetCurrentPortrait = function resetCurrentPortrait() {
    currentPortrait.value = DEFAULT_PORTRAIT_SLUG;
  };
  var isDefaultCurrentPortrait = computed(function () {
    return currentPortrait.value === DEFAULT_PORTRAIT_SLUG;
  });
  var checkIsOnlyDefaultPortraitAvailable = function checkIsOnlyDefaultPortraitAvailable(availablePortraits) {
    return Array.isArray(availablePortraits) && availablePortraits.length === 1 && availablePortraits.some(function (_ref) {
      var slug = _ref.slug;
      return slug === DEFAULT_PORTRAIT_SLUG;
    });
  };
  return {
    currentPortrait: currentPortrait,
    currentProjectPortraits: currentProjectPortraits,
    resetCurrentPortrait: resetCurrentPortrait,
    isDefaultCurrentPortrait: isDefaultCurrentPortrait,
    checkIsOnlyDefaultPortraitAvailable: checkIsOnlyDefaultPortraitAvailable
  };
});