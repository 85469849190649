export const state = () => ({
  values: {},
});

export const actions = {
  setValues({ commit }, payload) {
    commit('SET_VALUES', payload);
  },

  resetValues({ commit }) {
    commit('RESET_VALUES');
  },
};

export const getters = {
  getValues(state) {
    return state.values;
  },
};

export const mutations = {
  SET_VALUES(state, payload) {
    if (payload && typeof payload === 'object' && Object.keys(payload).length > 0) {
      state.values = payload;
    }
  },

  RESET_VALUES(state) {
    state.values = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
