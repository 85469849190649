var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('s-input-range', _vm._b({
    staticClass: "panel-kit-input-range",
    on: {
      "input": _vm.handleInput
    }
  }, 's-input-range', _vm.$props, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };