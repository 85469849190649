var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "panel-content",
    class: _vm.$style.error
  }, [_c('div', {
    staticClass: "panel-block"
  }, [_vm.isNotFoundErrorType ? _c('Er404') : _c('ErrorWork')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };