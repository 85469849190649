/**
 * Инициализация активной встречи
 */
import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import {
  getUserNamespacedCookie,
} from '@@/src/shared/lib/helpers/store/cookie';
import {
  isMeetingAvailableOnRoute,
} from '@@/src/shared/lib/helpers/route/routeOption';
import { usePortraitsService } from '@@/src/shared/lib/services/portraits';

export default defineNuxtMiddleware(async ({ $cookies, store, route, redirect, $auth }) => {
  if (
    !$auth.loggedIn
    || !isMeetingAvailableOnRoute(route)
  ) {
    return;
  }
  const portraitsService = usePortraitsService();

  await portraitsService.getCurrentPortrait();
  await portraitsService.getProjectPortraits();

  const routeName = route.name;
  if (routeName?.includes('project-slug-meeting-index-') && store.getters['userRights/isNoMeetingsAccess']) {
    return redirect({ name: 'project-slug-meeting-index', params: route.params });
  }

  await store.dispatch('meeting/getActiveMeeting', { isSkipExistingMeeting: true });

  // Если удалось получить текущую встречу, то восстановить состояние начала презентации,
  // иначе - сбросить
  const isDemoStarted = getUserNamespacedCookie(
    $cookies,
    'isDemoStarted',
    $auth.user?.id,
  );

  store.commit('meeting/SET_DEMO', Boolean(isDemoStarted));
});
