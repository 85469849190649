import { useAboutPageStore, useFavoritesStore, useFlatsStore } from '@@/src/shared/@pinia';

export const state = () => ({});

export const getters = {};

export const actions = {
  resetSessionState({ dispatch }) {
    dispatch('mortgage/resetValues', {}, { root: true });
    dispatch('survey/clearSurveyMeeting', {}, { root: true });

    useFavoritesStore().clearFavorites();
    useAboutPageStore()?.reset();
    useFlatsStore().reset();
  },
};

export const mutations = {};
