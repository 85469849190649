import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
export var useProjectsStore = definePiniaStore('projects-store', function () {
  var projectsList = ref(null);
  function getProjectByProfitbaseId(profitbaseId) {
    if (!profitbaseId || !Array.isArray(projectsList.value)) {
      return;
    }
    return projectsList.value.find(function (_ref) {
      var pk = _ref.pk;
      return String(pk) === String(profitbaseId);
    });
  }
  var activeProject = ref(null);
  function setActiveProjectBySlug(slug) {
    if (!slug || !projectsList.value) {
      return;
    }
    var project = projectsList.value.find(function (item) {
      return (item === null || item === void 0 ? void 0 : item.slug) === slug;
    });
    if (!project) {
      project = activeProject.value || projectsList.value[0] || null;
    }
    if (!project) {
      return;
    }
    activeProject.value = project;
  }
  var activeProjectId = computed(function () {
    var _activeProject$value;
    return (_activeProject$value = activeProject.value) === null || _activeProject$value === void 0 ? void 0 : _activeProject$value.pk;
  });
  var activeProjectSlug = computed(function () {
    var _activeProject$value2;
    return (_activeProject$value2 = activeProject.value) === null || _activeProject$value2 === void 0 ? void 0 : _activeProject$value2.slug;
  });
  var activeProjectCity = computed(function () {
    var _activeProject$value3;
    return (_activeProject$value3 = activeProject.value) === null || _activeProject$value3 === void 0 ? void 0 : _activeProject$value3.city;
  });
  return {
    activeProject: activeProject,
    setActiveProjectBySlug: setActiveProjectBySlug,
    projectsList: projectsList,
    getProjectByProfitbaseId: getProjectByProfitbaseId,
    activeProjectId: activeProjectId,
    activeProjectSlug: activeProjectSlug,
    activeProjectCity: activeProjectCity
  };
});