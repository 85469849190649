export const DEFAULT_USER_ROLE = 'content_manager';
export const ADMIN_USER_ROLE = 'panel_admin';
export const SALES_MANAGER_USER_ROLE = 'sales_manager';
export const REALTOR_USER_ROLE = 'realtor';

/**
 * Массив доступных ролей упорядочен по убыванию привилегированности.
 * Для определения единственной роли ищется наиболее старшая из доступных ролей.
 * Роль по умолчанию - самая непривилегированная.
 * @type {(string)[]}
 */
export const AVAILABLE_USER_ROLES = [
  ADMIN_USER_ROLE,
  SALES_MANAGER_USER_ROLE,
  REALTOR_USER_ROLE,
  DEFAULT_USER_ROLE,
] as const;

export const FULL_ACCESS_USER_ROLES = [
  ADMIN_USER_ROLE,
] as const;

export const ALL_PAGES_ACCESS_USER_ROLES = [
  DEFAULT_USER_ROLE,
  ...FULL_ACCESS_USER_ROLES,
] as const;

export const NO_MEETINGS_ACCESS_USER_ROLES = [
  DEFAULT_USER_ROLE,
] as const;

export const NO_MEETINGS_HISTORY_USER_ROLES = [
  REALTOR_USER_ROLE,
  SALES_MANAGER_USER_ROLE,
] as const;

export type RoleGroups = typeof NO_MEETINGS_ACCESS_USER_ROLES | typeof NO_MEETINGS_HISTORY_USER_ROLES | typeof ALL_PAGES_ACCESS_USER_ROLES | typeof FULL_ACCESS_USER_ROLES;
