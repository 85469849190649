var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.FilterContainer
  }, [_vm.label || _vm.postfix && !_vm.row ? _c('div', {
    class: _vm.$style.title
  }, [_vm.label ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.label)
    }
  }) : _vm._e(), _vm._v(" "), _vm.postfix && !_vm.row ? _c('span', {
    class: _vm.$style.postfix,
    domProps: {
      "innerHTML": _vm._s(_vm.postfix)
    }
  }) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('div', {
    class: _vm.$style.body
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };