var portalApiInstance;
var portalApiPlugin = function portalApiPlugin(ctx, inject) {
  var $config = ctx.$config,
    $axios = ctx.$axios;
  var API_PATH = '';
  var API_URL;
  if ($config.IS_LOCAL_MODE) {
    API_URL = process.client ? location.origin : $config.PANEL_SITE_URL;
  } else {
    API_URL = process.client ? $config.PANEL_SITE_URL : $config.MAIN_SERVER_API_URL;
  }
  API_URL += API_PATH;
  portalApiInstance = $axios.create({
    baseURL: API_URL
  });
  portalApiInstance.setHeader('Platform', 'panel');
  inject('portalApi', portalApiInstance);
  ctx.$portalApi = portalApiInstance;
};
export default portalApiPlugin;
export var usePortalApi = function usePortalApi() {
  if (!portalApiInstance) {
    throw new Error('Portal API instance is not defined :(');
  }
  return portalApiInstance;
};