const state = () => ({
  client: null,
});

export const getters = {
  getClientId(state) {
    return state.client?.id;
  },
};

const actions = {
  setClient({ commit }, payload) {
    commit('ADD_CLIENT', payload);
  },
};

const mutations = {
  ADD_CLIENT(state, payload) {
    state.client = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
