import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { isAuthRequiredOnRoute } from '@@/src/shared/lib/helpers/route/routeOption';

const LOGIN_PAGE_ROUTE = 'index';

export default defineNuxtMiddleware(async ({ route, redirect, $auth }) => {
  if ($auth.loggedIn || !isAuthRequiredOnRoute(route)) {
    return;
  }

  if (route.name !== LOGIN_PAGE_ROUTE) {
    redirect({
      name: LOGIN_PAGE_ROUTE,
      query: {
        redirect: route.fullPath,
      },
    });
  }
});
