import { stringToBase64 } from '@@/src/shared/lib/utils/base64Utils';

const processProjectFilter = (projectSlug, variables) => {
  const encodedProjectId = slug => stringToBase64(`ProjectType:${slug}`);
  const encodedActiveProject = encodedProjectId(projectSlug);

  const convertFilteredProjectToEncoded = (project) => {
    if (!project) {
      return null;
    }

    if (typeof project === 'string') {
      // Если фильтр по проекту - массив строк, то есть массив slug, а не идентификаторов
      return encodedProjectId(project);
    }
    else {
      // todo: Если фильтр по проекту - массив чисел, то найти slug по id
      return encodedActiveProject;
    }
  };

  if (Array.isArray(variables.project)) {
    const encodedFilteredProjects = variables.project
      .map(convertFilteredProjectToEncoded)
      .filter(Boolean);
    // Только уникальные значения
    return [...new Set(encodedFilteredProjects)];
  }

  return convertFilteredProjectToEncoded(variables.project) || encodedActiveProject;
};

export function convertGraphQLVariables(projectSlug, variables) {
  variables = Object.fromEntries(
    Object
      .entries(variables)
      .filter(([, value]) => Boolean(value)),
  );

  if ('limit' in variables && variables.limit) {
    variables.first = variables.limit;
    delete variables.limit;
  }
  if ('offset' in variables && variables.offset) {
    variables.after = stringToBase64(`arrayconnection:${variables.offset - 1}`);
    delete variables.offset;
  }

  if ('project' in variables && variables.project) {
    variables.project = processProjectFilter(projectSlug, variables);
  }

  return variables;
}
