import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('s-group', {
    attrs: {
      "label": _vm.label,
      "theme": _vm.groupTheme,
      "disabled": _vm.disabled,
      "filter": ""
    }
  }, _vm._l(_vm.spec, function (item) {
    return _c('s-chip', {
      key: "choices-".concat(item.value),
      attrs: {
        "value": item.value,
        "model-value": _vm.values,
        "theme": _vm.chipTheme,
        "multiple": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('change', $event);
        }
      }
    }, [_vm._v("\n    " + _vm._s(item.label) + "\n  ")]);
  }), 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };