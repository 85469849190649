import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.timers.js";
import Vue from 'vue';
import { useBugReportService } from '@@/src/shared/api/BugReport/BugReport.service';
import { useErrorLogger } from '@@/src/app/plugins';
import { usePortraitsStore } from '@@/src/shared/@pinia/use-portraits-store';
import axios from 'axios';
;
;
;
var portraitResolver;
var nonReportableErrorCodes = [418];
function isNonReportableError(error) {
  var _error$response$statu, _error$response;
  if (!axios.isAxiosError(error)) {
    return false;
  }
  return nonReportableErrorCodes.includes((_error$response$statu = (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) !== null && _error$response$statu !== void 0 ? _error$response$statu : 0);
}
export var useModalsStore = definePiniaStore('modals-store', {
  state: function state() {
    return {
      statusModalTimeout: null,
      statusModal: {
        state: false,
        title: '',
        content: ''
      },
      portraitModal: {
        state: false,
        options: null
      }
    };
  },
  actions: {
    showPortraitModal: function showPortraitModal(options) {
      if (options === undefined) {
        options = usePortraitsStore().currentProjectPortraits;
      }
      this.portraitModal = {
        state: true,
        options: options
      };
      return new Promise(function (resolve) {
        portraitResolver = resolve;
      });
    },
    closePortraitModal: function closePortraitModal() {
      var status = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.portraitModal = {
        state: false,
        options: null
      };
      if (portraitResolver) {
        portraitResolver(status);
      }
    },
    setStatusModalState: function setStatusModalState(payload) {
      Vue.set(this, 'statusModal', payload);
    },
    showStatusModal: function showStatusModal(payload) {
      var _this = this;
      var closeAfter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3000;
      if (this.statusModalTimeout) {
        clearTimeout(this.statusModalTimeout);
      }
      this.statusModal = _objectSpread(_objectSpread({}, payload), {}, {
        state: true
      });
      if (typeof closeAfter === 'number' && closeAfter > 0) {
        this.statusModalTimeout = setTimeout(function () {
          _this.hideStatusModal();
        }, closeAfter);
      }
    },
    showBugReportModal: function showBugReportModal(payload) {
      var _payload$bugReport,
        _this2 = this;
      var _useBugReportService = useBugReportService(),
        bugReportClient = _useBugReportService.bugReportClient,
        prepareBugReport = _useBugReportService.prepareBugReport,
        getErrorMessage = _useBugReportService.getErrorMessage;
      var modalPayload = payload.modal || {};
      var error = (_payload$bugReport = payload.bugReport) === null || _payload$bugReport === void 0 ? void 0 : _payload$bugReport.error;
      var errorText = getErrorMessage(error);
      if (errorText) {
        modalPayload.title = errorText;
      }
      var action = null;
      var shouldSendReport = !isNonReportableError(error);
      if (shouldSendReport) {
        if (error) {
          useErrorLogger().log(error);
        }
        var preparedBugReport = prepareBugReport(payload.bugReport || {});
        action = function action() {
          bugReportClient.sendBugReport(preparedBugReport);
        };
        if (process.server) {
          action();
          return;
        }
        var bugReportPayload = {
          content: "\n            <p>\u0427\u0442\u043E-\u0442\u043E \u043F\u043E\u0448\u043B\u043E \u043D\u0435 \u0442\u0430\u043A!</p>\n            <p>\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u043E\u0442\u043F\u0440\u0430\u0432\u044C\u0442\u0435 \u043E\u0442\u0447\u0451\u0442 \u043E\u0431 \u043E\u0448\u0438\u0431\u043A\u0435, \u0447\u0442\u043E\u0431\u044B \u043C\u044B \u0441\u043C\u043E\u0433\u043B\u0438 \u0435\u0451 \u0443\u0441\u0442\u0440\u0430\u043D\u0438\u0442\u044C</p>\n          ",
          button: {
            action: action,
            text: 'Отправить отчет'
          }
        };
        this.showStatusModal(_objectSpread(_objectSpread({}, modalPayload), bugReportPayload), false);
      } else {
        action = function action() {
          _this2.hideStatusModal();
        };
        var noReportPayload = {
          button: {
            action: action,
            text: 'Понятно'
          }
        };
        this.showStatusModal(_objectSpread(_objectSpread({}, modalPayload), noReportPayload), false);
      }
    },
    hideStatusModal: function hideStatusModal() {
      var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (this.statusModalTimeout) {
        clearTimeout(this.statusModalTimeout);
      }
      this.statusModal = _objectSpread(_objectSpread({}, payload), {}, {
        state: false
      });
    }
  }
});