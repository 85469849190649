import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import Er404 from '@@/src/components/pages/error/Er404';
import ErrorWork from '@@/src/components/pages/error/ErrorWork';
export default {
  name: 'Error',
  components: {
    Er404: Er404,
    ErrorWork: ErrorWork
  },
  props: {
    error: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    isNotFoundErrorType: function isNotFoundErrorType() {
      return this.error.statusCode === 404;
    }
  }
};