import type { Context } from '@nuxt/types';
import { useErrorLogger } from '@@/src/app/plugins';

const apiErrorHandler = ({ $axios, $cabinetsApi, $portalApi, $panelApi, $servicesApi }: Context) => {
  [
    $axios,
    $cabinetsApi,
    $portalApi,
    $panelApi,
    $servicesApi,
  ].forEach((apiInstance) => {
    apiInstance?.onError((error) => {
      console.error('Проблема получения данных:', error);
      useErrorLogger().log(error);
    });
  });
};

export default apiErrorHandler;
