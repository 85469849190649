var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "portrait-actions"
  }, [_c('s-button', {
    staticClass: "portrait-button",
    attrs: {
      "theme": "ghost",
      "equal": "",
      "size": "small"
    },
    on: {
      "click": _setup.onChangePortraitButtonClick
    }
  }, [_c('s-icon', {
    staticClass: "portrait-button__icon",
    class: {
      'portrait-button__icon--active': _setup.hasActivePortrait
    },
    attrs: {
      "icon": _setup.iconName,
      "size": "16"
    }
  })], 1), _vm._v(" "), _setup.hasActivePortrait ? _c('s-button', {
    staticClass: "close-button",
    attrs: {
      "theme": "grey-slate",
      "equal": "",
      "size": "custom"
    },
    on: {
      "click": _setup.onResetPortraitButtonClick
    }
  }, [_c('s-icon', {
    attrs: {
      "color": "white",
      "icon": "close",
      "size": "20"
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };