import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import cookieUniversal from 'cookie-universal';
export default (function (_ref, inject) {
  var req = _ref.req,
    res = _ref.res;
  var options = {
    "alias": "cookies",
    "parseJSON": true
  };
  inject(options.alias, cookieUniversal(req, res, options.parseJSON));
});