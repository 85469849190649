const { ENVIRONMENT, PANEL_SITE_HOST } = process?.env;

export enum EnvironmentType {
  Dev = 'development',
  Stage = 'pre-production',
  Prod = 'production',
  Local = 'local',
}

export const ENVIRONMENTS_DICTIONARY: Record<string, EnvironmentType> = {
  [EnvironmentType.Dev]: EnvironmentType.Dev,
  [EnvironmentType.Stage]: EnvironmentType.Stage,
  [EnvironmentType.Prod]: EnvironmentType.Prod,
  [EnvironmentType.Local]: EnvironmentType.Local,
};

export const HOSTS_DICTIONARY: Record<string, EnvironmentType> = {
  'panel.stranadev-new.com': EnvironmentType.Dev,
  'panel.stranastage-new.com': EnvironmentType.Stage,
  'panel.strana.com': EnvironmentType.Prod,
  'localhost': EnvironmentType.Local,
  'undefined': EnvironmentType.Local,
};

export const getInstanceEnvironmentType = (
  environment: string | undefined = ENVIRONMENT,
  host: string | undefined = PANEL_SITE_HOST,
): EnvironmentType => {
  if (environment && environment in ENVIRONMENTS_DICTIONARY) {
    return ENVIRONMENTS_DICTIONARY[environment];
  }

  for (const iterationHost in HOSTS_DICTIONARY) {
    if (!host?.includes(iterationHost)) {
      continue;
    }

    return HOSTS_DICTIONARY[iterationHost];
  }

  return EnvironmentType.Dev;
};
