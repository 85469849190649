// TODO: логика полностью повторяет логику из личного кабинета клиента, когда-то нужно обобщить (вынести в общий репозиторий)
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import type { MetaInfo } from 'vue-meta/types/vue-meta';
import { EnvironmentType, getInstanceEnvironmentType } from '@@/src/shared/lib/utils/environmentUtils';
import { YANDEX_METRIKA } from './yandex-metrika';

const ENVIRONMENT = getInstanceEnvironmentType();

export type Script = NonNullable<MetaInfo['script']>[number];
export type Noscript = NonNullable<MetaInfo['noscript']>[number];

export interface AnalyticsScript {
  script: Script;
  noscript: Noscript;
};

export default defineNuxtPlugin((context) => {
  const { head } = context.app;

  const globalHeads: AnalyticsScript[] = [];

  if (ENVIRONMENT === EnvironmentType.Prod) {
    globalHeads.push(YANDEX_METRIKA);
  }
  else {
    const { query } = context.route;

    if ('yandex_metrika' in query) {
      globalHeads.push(YANDEX_METRIKA);
    }
  }

  if (globalHeads.length && head && typeof head !== 'function') {
    head.__dangerouslyDisableSanitizers = ['script', 'noscript'];
    globalHeads.forEach(({ script, noscript }) => {
      // TODO: обобщить при следующей итерации работы со скриптами (сейчас туго работает тс, если перебирать поля)
      if (script) {
        if (!head.script) {
          head.script = [];
        }
        head.script.push(script);
      }

      if (noscript) {
        if (!head.noscript) {
          head.noscript = [];
        }
        head.noscript.push(noscript);
      }
    });
  }
});
