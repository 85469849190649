import { RestClient } from '@@/src/shared/api/Base/Rest.client';
import { usePanelApi } from '@@/src/app/plugins';
import { routes } from './routes';

interface CurrentProject { slug: string };
class UserProjectClient extends RestClient {
  async getCurrent() {
    try {
      return this.sendGetRequest<CurrentProject>(routes.project, {});
    }
    catch (error) {
      console.error('[UserProject::getCurrent]', error);

      return null;
    }
  }

  async setCurrent(slug: string) {
    try {
      return this.sendPostRequest<CurrentProject>(routes.project, {}, { slug });
    }
    catch (error) {
      console.error('[UserProject::setCurrent]', error);

      return null;
    }
  }
}

export const useUserProjectClient = () => new UserProjectClient(usePanelApi());
