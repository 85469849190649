import type {
  Context,
  Plugin,
} from '@nuxt/types';
import type {
  Inject,
} from '@nuxt/types/app';

const axiosPlugin: Plugin = (ctx: Context) => {
  const {
    $config,
    $axios,
    req,
  } = ctx;

  // region Установка заголовков
  const headers = (req && req.headers
    ? ({ ...req.headers })
    : {}) as Record<string, string>;
  $axios.defaults.xsrfCookieName = 'csrftoken';
  $axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  $axios.setHeader('X-Forwarded-Host', headers['x-forwarded-host']);
  $axios.setHeader('X-Forwarded-Port', headers['x-forwarded-port']);
  $axios.setHeader('X-Forwarded-Proto', headers['x-forwarded-proto']);
  // endregion

  let API_URL;
  const API_PATH = '/';

  if ($config.IS_LOCAL_MODE) {
    API_URL = process.client ? location.origin : $config.PANEL_SITE_URL;
  }
  else {
    API_URL = process.client ? $config.PANEL_SITE_URL : $config.PANEL_SERVER_API_URL;
  }

  API_URL += API_PATH;

  $axios.setBaseURL(API_URL);
};

export default axiosPlugin;
