// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_mKVV4{color:#677b8f;display:-webkit-box;display:-ms-flexbox;display:flex;font-size:1.4rem;line-height:1.46;margin-bottom:1.2rem}@media(max-width:767.98px){.title_mKVV4{font-size:12px;line-height:1.32;margin-bottom:.8rem}}.postfix_he4bn{margin-left:auto}.body_Y5P2D{display:-webkit-box;display:-ms-flexbox;display:flex;position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_mKVV4",
	"postfix": "postfix_he4bn",
	"body": "body_Y5P2D"
};
module.exports = ___CSS_LOADER_EXPORT___;
