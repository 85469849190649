// Interfaces
import type { NuxtAxiosInstance } from '@nuxtjs/axios';

// Types
import type { Node } from '@@/src/shared/api/Base/types';
import type {
  City,
  Project,
  ProjectBuilding,
  ProjectPresentation,
  ProjectProgress,
  ProjectProgressAlbum,
  ProjectProgressVariables,
  ProjectWebcam,
  ProjectsQueryVariables,
} from '@@/src/shared/api/Project/types';

// Queries
import {
  globalProjectsQuery,
  projectBuildingsQuery,
  projectCityQuery,
  projectPresentationQuery,
  projectProgressAlbumQuery,
  projectProgressFacetsQuery,
  projectProgressQuery,
  projectProgressSpecsQuery,
  projectQuery,
  projectWebcamQuery,
} from '@@/src/shared/api/Project/queries';

import { BaseClient } from '@@/src/shared/api/Base/Base.client';
import { getEncodedId } from '@@/src/shared/lib/utils/commonUtils';
import { usePortalApi } from '@@/src/app/plugins';

class ProjectClient extends BaseClient {
  readonly #slug: string;

  constructor($axios: NuxtAxiosInstance, slug = '') {
    super($axios);
    this.#slug = slug;
  }

  #getProjectId() {
    return getEncodedId(this.#slug, 'ProjectType');
  }

  /**
   * Получение информации о проекте
   */
  async get(): Promise<Partial<Project>> {
    try {
      const data = await this.sendRequest(
        projectQuery,
        {
          slug: this.#slug,
        },
      );

      const { project } = data;

      return project;
    }
    catch (error: any) {
      this.getError('getProject', error);

      return {};
    }
  }

  /**
   * Получение списка проектов с возможностью фильтрации по городу
   */
  async getGlobalList(variables: ProjectsQueryVariables): Promise<Project[]> {
    try {
      const { result } = await this.sendRequest(globalProjectsQuery, variables);

      if (!result?.edges?.length) {
        return [];
      }

      return result.edges
        .filter((item: { node: Project | null }) => item && item.node)
        .map((item: { node: Project }) => item.node);
    }
    catch (error) {
      this.getError('getGlobalProjectsList', error);

      return [];
    }
  }

  /**
   * Получение информации о корпусах
   */
  async getProjectBuildings(projectId: string): Promise<ProjectBuilding[]> {
    try {
      const { result } = await this.sendRequest(
        projectBuildingsQuery,
        {
          projectId,
        },
      );

      return result.edges
        ?.map((item: { node: Node }) => item.node);
    }
    catch (error: any) {
      this.getError('getProjectBuildings', error);

      return [];
    }
  }

  /**
   * Получение города проекта
   */
  async getProjectCity(): Promise<City | object> {
    try {
      const data = await this.sendRequest(
        projectCityQuery,
        {
          slug: this.#slug,
        },
      );

      const { project } = data ?? {};

      return project?.city ?? null;
    }
    catch (error: any) {
      this.getError('getProjectCity', error);

      return {};
    }
  }

  /**
   * Получение информации о камерах видеонаблюдения на проекте
   */
  async getWebcams(): Promise<ProjectWebcam[]> {
    try {
      const data = await this.sendRequest(
        projectWebcamQuery,
        {
          slug: this.#slug,
        },
      );

      const { result } = data;

      return result && Array.isArray(result) && result.length
        ? result
        : [];
    }
    catch (error: any) {
      this.getError('getProjectWebcams', error);

      return [];
    }
  }

  /**
   * Получение информации о ходе строительства
   */
  async getProgress(variables: ProjectProgressVariables): Promise<ProjectProgress[]> {
    try {
      const data = await this.sendRequest(
        projectProgressQuery,
        {
          project: this.#getProjectId(),
          order: '-date',
          ...variables,
        },
      );

      const { result } = data;

      return result.edges?.map((item: { node: Node }) => item.node);
    }
    catch (error: any) {
      this.getError('projectProgress', error);

      return [];
    }
  }

  async getProgressSpecs() {
    try {
      const data = await this.sendRequest(
        projectProgressSpecsQuery,
        {
          project: this.#getProjectId(),
        },
      );

      const { result } = data ?? [];

      if (!Array.isArray(result) || !result?.length) {
        return [];
      }

      return Object.fromEntries(
        result.map(spec => [
          spec.name,
          spec.choices,
        ]),
      );
    }
    catch (error: any) {
      this.getError('getProgressSpecs', error);

      return {};
    }
  }

  async getProgressFacets(variables: ProjectProgressVariables) {
    try {
      const data = await this.sendRequest(
        projectProgressFacetsQuery,
        {
          project: this.#getProjectId(),
          ...variables,
        },
      );

      const { result } = data ?? [];
      const { facets, count } = result ?? {};

      if (!Array.isArray(facets) || !facets?.length) {
        return { facets: {}, count };
      }

      return {
        count,
        facets: Object.fromEntries(
          facets.map(facet => [
            facet.name,
            facet.choices,
          ]),
        ),
      };
    }
    catch (error: any) {
      this.getError('getProgressFacets', error);

      return {};
    }
  }

  /**
   * Получение альбома, связанного
   * с материалом о ходе строительства
   */
  async getProgressAlbum(slug: string): Promise<ProjectProgressAlbum> {
    try {
      const data = await this.sendRequest(
        projectProgressAlbumQuery,
        { slug },
      );

      const { progress } = data;

      return progress;
    }
    catch (error: any) {
      this.getError('projectProgressAlbum', error);

      return {};
    }
  }

  async getPresentation(): Promise<ProjectPresentation> {
    try {
      const data = await this.sendRequest(
        projectPresentationQuery,
        { slug: this.#slug },
      );

      const { result } = data;

      return result?.presentation || '';
    }
    catch (error: any) {
      this.getError('getPresentation', error);

      return '';
    }
  }
}

let baseProjectClient: ProjectClient | undefined;
export const useProjectClient = () => {
  if (!baseProjectClient) {
    baseProjectClient = new ProjectClient(usePortalApi());
  }

  return baseProjectClient;
};

export { ProjectClient };
