import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
var UNDEFINED_NUMBER = -1;
export default {
  name: 'PInputRange',
  props: {
    /**
     * Название элемента формы. Если передано, используется для события `input:keyed`
     */
    name: {
      type: String,
      default: ''
    },
    /**
     * Изменяет размер (высоту) компонента
     */
    size: {
      type: String,
      default: 'default'
    },
    /**
     * Изменяет цветовую схему компонента
     */
    theme: {
      type: String,
      default: 'panel-white'
    },
    /**
     * Разделяет введенное число по тысячным (1 000 000) в SInputRangeField
     */
    splitThousands: {
      type: Boolean,
      default: true
    },
    /**
     * Заголовок поля
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Выбранное значение
     */
    value: {
      type: Number,
      default: UNDEFINED_NUMBER
    },
    /**
     * Выбранное значение в процентах
     */
    percent: {
      type: Number,
      default: UNDEFINED_NUMBER
    },
    /**
     * Значение, на основе которого вычисляются проценты.
     * 100%
     */
    total: {
      type: Number,
      required: true
    },
    /**
     * Шаг изменения ползунка
     */
    step: {
      type: Number,
      required: true
    },
    /**
     * Минимальное значение ползунка.
     * Если передан входной параметр `percent`, то в процентах,
     * иначе - в единицах (в рублях).
     */
    min: {
      type: Number,
      required: true
    },
    /**
     * Максимальное значение ползунка
     * Если передан входной параметр `percent`, то в процентах,
     * иначе - в единицах (в рублях).
     *
     * Если в процентах, то может быть меньше 100%.
     */
    max: {
      type: Number,
      required: true
    }
  },
  emits: ['input', 'input:keyed'],
  methods: {
    handleInput: function handleInput($event) {
      this.$emit('input', $event);
      if (this.name) {
        this.$emit('input:keyed', _defineProperty({}, this.name, $event));
      }
    }
  }
};