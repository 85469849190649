import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
export default {
  name: 'Auth',
  head: function head() {
    return {
      title: 'Авторизация - Strana Панель менеджера'
    };
  }
};