import Vue from 'vue';

// @ts-expect-error Cannot find module '@strana-artw/common' or its corresponding type declarations.
import { VueScreen } from '@strana-artw/common';

import type { Screen } from '@strana-artw/common/plugins/screen/v3/ScreenReactivity.types';

import { SCREEN_BREAKPOINTS } from '@@/src/config/screen';

declare module 'vue/types/vue' {
  interface Vue {
    $screen: Screen;
  }
}

interface Device {
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
}

let screenPlugin: Screen | undefined;

export default ({ $device = {} satisfies Device }) => {
  Vue.use({ install: VueScreen.install }, {
    device: $device,
    breakpoints: SCREEN_BREAKPOINTS,
  });

  screenPlugin = Vue.prototype.$screen;
};

export const useScreenPlugin = () => {
  if (!screenPlugin) {
    throw new Error('Screen plugin is not defined.');
  }

  return screenPlugin;
};
