var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('s-modal', _vm._b({
    staticClass: "p-status-base-modal",
    on: {
      "close": _vm.handleClose
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_vm._t("top")];
      },
      proxy: true
    }, {
      key: "bottom",
      fn: function fn() {
        return [_c('div', {
          staticClass: "p-status-base-modal__bottom"
        }, [_vm.$slots.bottom ? _vm._t("bottom") : [_c('s-button', {
          staticClass: "p-status-base-modal-bottom-btn",
          attrs: {
            "size": _vm.isMobileOrTablet ? 'x-large' : 'large'
          },
          on: {
            "click": _vm.handleClose
          }
        }, [_vm._v("\n          Понятно\n        ")])]], 2)];
      },
      proxy: true
    }], null, true)
  }, 's-modal', _vm.totalModalProps, false), [_vm._v(" "), _c('div', {
    staticClass: "p-status-base-modal__content"
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };